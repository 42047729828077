import React, { Component } from "react";
import "./index.scss";
import request from "../../../assets/js/http";
import { Carousel, Button, message } from "antd";
import { Link, withRouter } from "react-router-dom";
import cookie from "react-cookies";
import newproduct from "../../../assets/image/newproduct.png";
import timelimit from "../../../assets/image/timelimit.png";
class BannerContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lunBoList: [],
      userInfo: [],
      daizhifu: "",
      daifahuo: "",
      daishouhuo: "",
      noticeList: [],
      recommendList: [],
    };
  }

  componentDidMount() {
    this.lunBo();
    this.noticeList();
    if (cookie.load("infoData")) {
      this.userInfo();
      this.orderNum();
    }
    this.recommend();
  }

  lunBo() {
    request("post", "/api/cms/slide/list").then((res) => {
      this.setState({
        lunBoList: res.data,
      });
    });
  }
  recommend() {
    request("post", "/api/goods/class/ztList").then((res) => {
      // console.log(res);
      this.setState({
        recommendList: res.data,
      });
    });
  }
  userInfo() {
    let userId = cookie.load("infoData").id;
    request("post", "/api/member/user/info", {
      userId,
    }).then((res) => {
      this.setState({
        userInfo: res.data,
      });
    });
  }
  orderNum() {
    let userId = cookie.load("infoData").id;
    request("post", "/api/order/order/orderNum", {
      userId,
    }).then((res) => {
      this.setState({
        daizhifu: res.data.daizhifu,
        daifahuo: res.data.daifahuo,
        daishouhuo: res.data.daishouhuo,
      });
    });
  }
  noticeList() {
    request("post", "/api/cms/notice/list").then((res) => {
      this.setState({
        noticeList: res.data,
      });
    });
  }
  //跳转问价
  goWenjia() {
    // console.log(123);
    console.log(this);
    this.props.history.push("/home/boutique/boutique");
  }

  jumpNotice(id) {
    this.props.history.push({ pathname: "/home/notice", state: { id } });
  }
  jumpUser() {
    if (cookie.load("infoData")) {
      this.props.history.push({ pathname: "/personalCenter/personalData" });
    } else {
      message.warning("请先登录");
    }
  }
  goDaiPay() {
    if (cookie.load("infoData")) {
      this.props.history.push({
        pathname: "/personalCenter/myOrder",
        state: { index: 0 },
      });
    } else {
      message.warning("请先登录");
    }
  }
  goDaiPay1() {
    if (cookie.load("infoData")) {
      this.props.history.push({
        pathname: "/personalCenter/myOrder",
        state: { index: 1 },
      });
    } else {
      message.warning("请先登录");
    }
  }
  goDaiPay2() {
    if (cookie.load("infoData")) {
      this.props.history.push({
        pathname: "/personalCenter/myOrder",
        state: { index: 2 },
      });
    } else {
      message.warning("请先登录");
    }
  }
  
  render() {
    const {
      lunBoList,
      userInfo,
      daizhifu,
      daifahuo,
      daishouhuo,
      noticeList,
      recommendList,
    } = this.state;
    const contentStyle = {
      height: "460px",
      color: "#fff",
      textAlign: "center",
      background: "#364d79",
    };
    return (
      <>
        <div className="bannerContentW">
          <div className="bannerContent">
            <div className="bannerContentLeft_menu">
              <div className="fill"></div>
              {recommendList.map((item) => (
                <div
                  className="recommend"
                  onClick={this.goWenjia.bind(this)}
                  key={item.id}
                >
                  <div className="title">{item.name}&nbsp;&gt;</div>
                  <div className="recommend_text">
                    <div>杀虫止痒药</div>
                    <div>杀虫止痒药</div>
                    <div>杀虫止痒药</div>
                    <div>杀虫止痒药</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="bannerContentMiddle_carousel">
              <Carousel autoplay>
                {lunBoList.map((item) => (
                  <div key={item.id} style={contentStyle}>
                    <img
                      style={{ width: "860px", height: "400px" }}
                      src={item.imgHead}
                      alt=""
                    />
                  </div>
                ))}
              </Carousel>
              <div className="bannerContentMiddleBottom">
                <Link to="/home/homeNewProduct">
                  <div
                    style={{
                      background: `#ffffff url(${newproduct}) no-repeat 100% 100%`,
                      backgroundSize: "100% 100%",
                    }}
                    className="bannerContentMiddleBottomItem"
                  >
                    <div className="name">新品推荐</div>
                    <span className="msg">品质有保证</span>
                  </div>
                </Link>
                <Link to="/home/homeTimeLimit">
                  <div
                    style={{
                      background: `#ffffff url(${timelimit}) no-repeat 100% 100%`,
                      backgroundSize: "100% 100%",
                    }}
                    className="bannerContentMiddleBottomItem"
                  >
                    <div className="name">限时抢购</div>
                    <span className="msg">周周特惠，天天低价</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="bannerContentRight_userInfo">
              <div className="headPortrait">
                <img src={userInfo ? userInfo.avator : ""} alt="" />
              </div>
              <div className="userName">{userInfo ? userInfo.name : ""}</div>
              {/* <Link  to={"/personalCenter/personalData"}> */}
              <Button onClick={() => this.jumpUser()}>个人中心</Button>
              {/* </Link> */}
              <div className="orderNum">
                <div className="orderNumItem">
                  <div className="num">{daizhifu}</div>
                  <div className="name" onClick={this.goDaiPay.bind(this)}>
                    待支付
                  </div>
                </div>
                <div className="orderNumItem">
                  <div className="num">{daifahuo}</div>
                  <div className="name" onClick={this.goDaiPay1.bind(this)}>
                    待发货
                  </div>
                </div>
                <div className="orderNumItem">
                  <div className="num">{daishouhuo}</div>
                  <div className="name" onClick={this.goDaiPay2.bind(this)}>
                    待收货
                  </div>
                </div>
              </div>
              <div className="cut-offRule"></div>
              <div className="noticeTitle">公告资讯</div>
              <div className="noticeList">
                 
                {noticeList.map((item) => (
                  <div
                    onClick={() => this.jumpNotice(item.id)}
                    key={item.id}
                    className="noticeItem"
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(BannerContent);
