import React, { Component } from 'react';
import { Link } from "react-router-dom"
import './index.scss';
import request from '../../../assets/js/http'
import { Pagination } from 'antd';
import homeDiscountsBanner from '../../../assets/image/home_discounts.png'
export default class HomeDiscounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            discountsList: []
        }
    }
    componentDidMount() {
        this.discountsList()
    }
    discountsList() {
        request('post', '/api/goods/specialDrug', {
            status: 3,
            pageNum: 1,
            pageSize: 10
        }).then(res => {
            this.setState({
                discountsList: res.data
            })
        })
    }
    discountsPagination = (pageNum) => {
        request('post', '/api/goods/specialDrug', {
            status: 3,
            pageNum,
            pageSize: 10
        }).then(res => {
            this.setState({
                discountsList: res.data
            })
        })
    }
    render() {
        const { discountsList } = this.state
        return (
            <div className="homeDiscounts">
                <div className="homeDiscountsBanner">
                    <img src={homeDiscountsBanner} alt="" />
                </div>
                <div className="homeDiscountsGoodsList">
                    {
                        discountsList.list && discountsList.list.map(item => (
                            <Link target="_blank" key={item.goodsId} className="LinkJump" to={'/home/productDetails/' + item.goodsId}>
                                <div className="homeDiscountsGoodsItem">
                                    <div className="homeDiscountsGoodsItemImg">
                                        <img src={item.imgurl} alt="暂无图片" />
                                    </div>
                                    <div className="homeDiscountsGoodsName">
                                        {item.name}
                                    </div>
                                    <div className="homeDiscountsGoodsItemPrice">
                                        <div className="discountedPrice">
                                            ￥ <span className="discountedPriceNum">{item.priceH}</span>
                                            {/* <span className="discountPriceNum">￥899.00</span> */}
                                        </div>
                                    </div>
                                    <div className="homeDiscountsGoodsItemBottom">
                                        {/* <div className="spec">{item.unit}</div> */}
                                        <div className="city">{item.city}</div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }

                </div>
                <Pagination pageSize='10' current={discountsList.pageNum} total={discountsList.total} onChange={this.discountsPagination} />
            </div>
        )
    }
}