import React, { Component } from 'react';
import './index.scss';
import { Collapse } from 'antd';
import cookie from "react-cookies";
import request from '../../../assets/js/http';
const { Panel } = Collapse;
export default class MyMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsList:''
        }
    }
    componentDidMount() {
        this.newsList()
    }
    callback(key) {
        console.log(key);
    }
    newsList() {
        let userId = cookie.load("infoData").id;
        request('post', '/api/cms//news/list', {
            pageNum: 1,
            pageSize: 10,
            userId,
            status: '0'
        }).then(res => {
            this.setState({
                newsList: res.data
            })
            console.log(res);
        })
    }
    // /api/cms//news/list
    render() {
        const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

        return (
            <div className="myMessage">
                <div className="myMessageTitle">
                    我的消息
                </div>
                <Collapse defaultActiveKey={['0']} onChange={this.callback}>
                    {
                        this.state.newsList&&this.state.newsList.list.map((item,index) => (
                            <Panel header={item.title} key={index}>
                                <p>{item.content}</p>
                            </Panel>
                        ))
                    }

                    {/* <Panel header="This is panel header 2" key="2">
                        <p>{text}</p>
                    </Panel>
                    <Panel header="This is panel header 3" key="3">
                        <p>{text}</p>
                    </Panel> */}
                </Collapse>,
            </div>
        )
    }
}