import React, { Component } from "react";
import {
  DatePicker,
  Space,
  ConfigProvider,
  Input,
  Pagination,
  message,
} from "antd";
import request from "../../../assets/js/http";
import moment from "moment";
import "moment/locale/zh-cn";
import zhCN from "antd/es/locale/zh_CN";
import "./index.scss";
import cookie from "react-cookies";
const dateFormat = "YYYY/MM/DD";
export default class MyOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: [
        { name: "全部", id: 5, type: 5 },
        { name: "待支付", id: 0, type: 0 },
        { name: "待发货", id: 1, type: 1 },
        { name: "待收货", id: 2, type: 2 },
      ],
      currentIndex: 5,
      myOrderList: [],
      total: 0,
    };
  }
  componentDidMount() {
    // console.log(this.props.location.state.index);
    if (this.props.location.state) {
      this.setState({
        currentIndex: this.props.location.state.index,
      });
    }

    this.myOrderList();
  }
  setCurrentIndex(id) {
    console.log(id);
    this.setState(
      {
        currentIndex: id,
      },
      function () {
        this.myOrderList();
      }
    );
  }
  myOrderList() {
    let userId = cookie.load("infoData").id;
    const { currentIndex } = this.state;
    request("post", "/api/order/order/getOrderList", {
      userId,
      type: currentIndex + "",
      pageNum: "1",
      pageSize: "6",
      name: "",
    }).then((res) => {
      console.log(res);
      this.setState({
        myOrderList: res.data.list,
        total: res.data.total,
      });
    });
  }
  onChange(e) {
    let userId = cookie.load("infoData").id;
    const { currentIndex } = this.state;
    request("post", "/api/order/order/getOrderList", {
      userId,
      type: currentIndex + "",
      pageNum: e,
      pageSize: "6",
      name: "",
    }).then((res) => {
      console.log(res);
      this.setState({
        myOrderList: res.data.list,
        total: res.data.total,
      });
    });
  }
  goDetails(orderId) {
    console.log(1);
    this.props.history.push({
      pathname: "/home/orderDetails",
      state: { orderId: orderId },
    });
  }
  getCurrent(status) {
    if (this.state.currentIndex == 0) {
      return <td>待支付</td>;
    } else if (this.state.currentIndex == 1) {
      return <td>待发货</td>;
    } else if (this.state.currentIndex == 2) {
      return <td>待收货</td>;
    } else {
      if (status === 0) {
        return <td>待支付</td>;
      } else if (status === 1) {
        return <td>待发货</td>;
      } else if (status === 2) {
        return <td>待收货</td>;
      } else if (status === 3) {
        return <td>待评价</td>;
      } else if (status === 4) {
        return <td>已评价</td>;
      } else if(status == 8) {
        return <td>已取消</td>;
      }else{
        return <td>交易成功</td>
      }
    }
  }
  tixing(e) {
    console.log(e);
    e.stopPropagation();
    message.success("提醒成功");
  }
  getCurrent1(status) {
    if (this.state.currentIndex == 0) {
      return <td>去支付</td>;
    } else if (this.state.currentIndex == 1) {
      return <td onClick={this.tixing.bind(this)}>提醒发货</td>;
    } else if (this.state.currentIndex == 2) {
      return <td>请收货</td>;
    } else {
      if (status === 0) {
        return <td>去支付</td>;
      } else if (status === 1) {
        return <td>提醒发货</td>;
      } else if (status === 2) {
        return <td>请收货</td>;
      } else if (status === 3) {
        return <td>请评价</td>;
      } else if (status === 4) {
        return <td>已评价</td>;
      }  else if(status == 8) {
        return <td>已取消</td>;
      }else{
        return <td>交易成功</td>
      }
    }
  }
  search(e) {
    let userId = cookie.load("infoData").id;
    const { currentIndex } = this.state;

    console.log(e.target.value - 0);
    request("post", "/api/order/order/getOrderList", {
      userId,
      type: currentIndex + "",
      pageNum: "1",
      pageSize: "6",
      name: e.target.value,
    }).then((res) => {
      console.log(res);
      this.setState({
        myOrderList: res.data.list,
        total: res.data.total,
      });
    });
  }
  render() {
    const { type, myOrderList, total } = this.state;

    return (
      <div className="myOrder">
        <div className="myOrderTop">
          {/* <Space direction="vertical" size={12}>
            <span>下单时间</span>
            <ConfigProvider locale={zhCN}>
              <DatePicker
                defaultValue={moment("2021/01/01", dateFormat)}
                format={dateFormat}
              />
            </ConfigProvider>
          </Space> */}
          <div className="orderNumber">
            <span className="name">订单编号</span>
            <Input
              onBlur={this.search.bind(this)}
              placeholder="请输入订单编号"
            />
          </div>
          <div className="orderNumber">
            <span className="name">商品名称</span>

            <Input
              onBlur={this.search.bind(this)}
              placeholder="请输入商品名称"
            />
          </div>
        </div>

        <div className="myOrderContent">
          <div className="myOrderContentTop">
            <div className="myOrderContentTop_left">
              <div className="myOrderTitleType">
                {type.map((item, i) => (
                  <div
                    key={item.id}
                    className={`myOrderTitleTypeItem ${
                      this.state.currentIndex === item.id ? "active" : ""
                    }`}
                    onClick={() => this.setCurrentIndex(item.id)}
                  >
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="myOrderContentTop_right">
              {/* <div className="del">删除订单</div> */}
            </div>
          </div>
          <div className="myOrderContentTable">
            <table className="table">
              <thead className="tableThead">
                <tr className="theadTr">
                  <th>订单编号</th>
                  <th>下单时间</th>
                  <th>商品图片</th>
                  <th>商品名称</th>
                  <th>规格</th>
                  <th>数量</th>
                  <th>订单金额</th>
                  <th>状态</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody className="tableTbody">
                {myOrderList.map((item) => {
                  return (
                    <tr
                      onClick={this.goDetails.bind(this, item.orderId)}
                      className="tbodyTr"
                      key={item.orderSn}
                    >
                      <td>{item.orderSn}</td>
                      <td>{item.createAt}</td>
                      <td>
                        <img src={item.goodsList[0].imgurl} alt="" />
                      </td>
                      <td>{item.goodsList[0].goodsName}</td>
                      <td>500g</td>
                      <td>{item.goodsList[0].total}</td>
                      <td>￥{item.allPrice}</td>
                      {this.getCurrent(item.status)}
                      {this.getCurrent1(item.status)}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="myOrderTopFooter">
          <div className="checkAll">
            {/* <input type="checkbox" name="" id="" /> <span>全选</span> */}
          </div>
          <Pagination
            onChange={this.onChange.bind(this)}
            pageSize="6"
            defaultCurrent={1}
            total={total}
          />
        </div>
      </div>
    );
  }
}
