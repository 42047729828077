import React, { Component } from "react";
import "./index.scss";
import cookie from "react-cookies";
import request from "../../assets/js/http";
import di from "../../assets/image/di.png";
import confirmvx from "../../assets/image/confirmvx.png";
import yue from "../../assets/image/yue.png";
import back from "../../assets/image/back.png";
import zhi from "../../assets/image/zhifubaopng.png";
import {
  Breadcrumb,
  Radio,
  Avatar,
  Checkbox,
  Table,
  Input,
  Button,
  Modal,
  Form,
  Cascader,
  Space,
  message,
} from "antd";
export default class ConfirmOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentValue: 1,
      count: "",
      addressList: [],
      goodsId: "",
      goodsInfo: "",
      cartIdList: [],
      info: {},
      defaultValueItem: "",
      radioValue: 0,
    };
  }
  componentDidMount() {
    console.log(this.props);
    if (this.props.location.state.goodsInfo) {
      //接受商品详情页传参
      this.setState({
        count: this.props.location.state.cont,
        // goodsId: this.props.location.state.goodsInfo.goodsId,
        goodsInfo: this.props.location.state.goodsInfo,
      });
    } else {
      //接受购物车传参
      this.setState({
        cartIdList: this.props.location.state.cartIdList,
        info: this.props.location.state.info,
      });
    }
    // 收货地址列表
    this.getAddressList();
    // 获取商品信息
  }
  // 获取用户收货地址列表
  getAddressList() {
    let that = this;
    console.log(cookie.load("infoData"));
    request("post", "/api/member/address/list", {
      userId: cookie.load("infoData").id,
    }).then((res) => {
      console.log(res);
      if (res.code == 0) {
        res.data.map((item) => {
          if (item.defaultValue == 0) {
            that.setState({
              defaultValueItem: item,
            });
          }
        });
        that.setState({
          addressList: res.data,
        });
      }
    });
  }
  // // 获取商品详情
  // getDetails() {
  //     let that = this;
  //     let goodsId = that.state.goodsId;
  //     request('post', '/api/goods/goodsInfo', {
  //         goodsId: goodsId,
  //         userId: cookie.load('infoData').id
  //     }).then(res => {
  //         console.log(res);
  //         that.setState({
  //             goodsInfo: res.goodsInfo
  //         })
  //     })
  // }
  //获取订单备注
  textareaValue(e) {
    this.setState({
      buyerMsg: e.target.value,
    });
  }
  // 点击下单
  PlaceTheOrderDirectly = () => {
    let that = this;
    let goodsId = that.state.goodsId;
    const { defaultValueItem, buyerMsg, goodsInfo, paymentValue } = this.state;
    if (defaultValueItem) {
      request("post", "/api/order/goods/addOrder", {
        userId: cookie.load("infoData").id,
        addressId: defaultValueItem.id,
        total: goodsInfo.total + "",
        goodsId: goodsInfo.goodsId,
        productId: goodsInfo.productId,
        hasScore: "0",
        score: goodsInfo.score + "",
        buyerMsg: buyerMsg ? buyerMsg : "",
        type: "0",
        couponId: "-1",
        payType: paymentValue + "",
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          console.log(res.data);
          this.props.history.push({
            pathname: "/home/payment",
            state: { data: res.data },
          });
        }
        message.success(res.msg);
      });
    } else {
      message.warning("请选择默认地址");
    }
  };
  // 购物车下单
  addCartOrder = () => {
    let that = this;
    let goodsId = that.state.goodsId;
    const { defaultValueItem, buyerMsg, goodsInfo, paymentValue } = this.state;
    if (defaultValueItem) {
      request("post", "/api/order/cart/addCartOrder", {
        userId: cookie.load("infoData").id,
        addressId: defaultValueItem.id,
        cartList: this.props.location.state.cartIdList,
        hasScore: "0",
        score: goodsInfo.score + "",
        buyerMsg: buyerMsg ? buyerMsg : "",
        type: "0",
        couponId: "-1",
        payType: paymentValue + "",
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.props.history.push({
            pathname: "/home/payment",
            state: { data: res.data },
          });
        }
        message.success(res.msg);
      });
    } else {
      message.warning("请选择默认地址");
    }
  };
  changePaymentValue = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      paymentValue: e.target.value,
    });
  };
  change(e) {
    console.log(e);
    this.setState({
      radioValue: e.target.value,
    });
  }
  radio(e) {}
  render() {
    const radioStyle = {
      height: "50px",
      lineHeight: "50px",
      width: "100%",
      background: " #F9F9F9",
      border: "1px solid #EEEEEE",
      marginBottom: "10px",
      padding: "0 20px",
      display: "flex",
      alignItems: "center",
    };
    const { count, defaultValueItem, addressList } = this.state;
    const goodsInfo = this.state.goodsInfo;
    return (
      <div className="confirmOrderW">
        <div className="confirmOrder">
          <div className="confirmOrderTitle">
            <div className="confirmOrderTitle_left">确认收货地址</div>
            <div className="confirmOrderTitle_right">管理收货地址</div>
          </div>
          <div className="confirmOrderAddressList">
            <Radio.Group
              onChange={(e) => this.change(e)}
              value={this.state.radioValue}
            >
              {this.state.addressList.map((item, i) => (
                <Radio
                  key={i}
                  style={radioStyle}
                  onChange={(e) => this.radio(e)}
                  value={i}
                >
                  <div
                    className="confirmOrderAddressItem"
                    onClick={(e) => this.a(e)}
                  >
                    <div className="itemLeft">
                      <div className="di">
                        <img style={{ opacity: 1 }} src={di} alt="默认" />
                      </div>
                      <div className="nei">
                        <span>{item.fullName}</span>
                        <span>{item.phone}</span>
                        <span className="address">
                          <span>{item.province}</span>
                          <span>{item.city}</span>
                          <span>{item.county}</span>
                          <span>{item.address}</span>
                        </span>
                      </div>
                      <div className="xiugai" onClick={() => this.xian()}>
                        {/* 可修改地址 */}
                      </div>
                    </div>
                    <div className="flex1"></div>
                    <div className="itemRight">
                      <div className="moren" onClick={() => this.moren()}>
                        设置默认收货地址
                      </div>
                    </div>
                  </div>
                </Radio>
              ))}
            </Radio.Group>
          </div>
          <div className="orderInfo">
            <div className="orderInfoTitle">订单信息</div>
            <div className="orderInfoList">
              <table className="orderInfoTable">
                <thead className="orderInfoTable_thead">
                  <tr className="orderInfoTable_thead_tr">
                    <th>商品信息</th>
                    <th>商品名称</th>
                    <th>规格</th>
                    <th>数量</th>
                    <th>单价</th>
                    <th>小计</th>
                  </tr>
                </thead>
                <tbody className="orderInfoTable_tbody">
                  {this.props.location.state.goodsInfo ? (
                    <tr className="orderInfoTable_tbody_tr">
                      <td>
                        <img src={goodsInfo.imgurl} alt="" />
                      </td>
                      <td>{goodsInfo.goodsName}</td>
                      <td>{goodsInfo.spec}</td>
                      <td>{goodsInfo.total}</td>
                      <td>￥{goodsInfo.priceH}</td>
                      <td>
                        ￥
                        {this.props.location.state.goodsInfo
                          ? (
                              goodsInfo.priceH *
                              (goodsInfo.coefficient - 0) *
                              goodsInfo.total
                            ).toFixed(2)
                          : (
                              this.props.location.state.info.proAllPrice +
                              this.props.location.state.info.allPostFee
                            ).toFixed(2)}
                      </td>
                    </tr>
                  ) : (
                    this.props.location.state.info.goodsList.map((item) => (
                      <tr className="orderInfoTable_tbody_tr">
                        <td>
                          <img src={item.imgurl} alt="" />
                        </td>
                        <td>{item.goodsName}</td>
                        <td>{item.spec}</td>
                        <td>{item.total}</td>
                        <td>￥{item.priceH}</td>
                        <td>￥{item.priceH * item.coefficient * item.total}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="orderA">
                <div className="orderBeiZhu">
                  <span className="beiZhu">订单备注：</span>
                  <textarea
                    onChange={(e) => this.textareaValue(e)}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    placeholder="请填写备注信息"
                  ></textarea>
                </div>
                <div className="orderMoney">
                  {/* <div className="orderMoneyItem">
                    <span className="name">积分抵扣：</span>
                    <span className="value">100积分抵扣10元&gt;</span>
                  </div> */}
                  <div className="orderMoneyItem">
                    <span className="name">优惠券：</span>
                    <span className="value">0张可用&gt;</span>
                  </div>
                  <div className="orderMoneyItem">
                    <span className="name">运费：</span>
                    <span className="price">
                      ￥
                      {this.props.location.state.goodsInfo
                        ? goodsInfo.postFee
                        : this.props.location.state.info.allPostFee}
                    </span>
                  </div>
                </div>
              </div>
              <div className="modeOfPayment">
                <span className="modeOfPaymentText">支付方式</span>
                <Radio.Group
                  onChange={(e) => this.changePaymentValue(e)}
                  value={this.state.paymentValue}
                >
                  <Radio value={0}>
                    <img src={confirmvx} alt="" /> 微信支付
                  </Radio>
                  {/* <Radio value={2}>
                    <img src={yue} alt="" /> 余额支付
                  </Radio> */}
                  <Radio value={1}>
                    <img style={{ width: 26, height: 27 }} src={zhi} alt="" />{" "}
                    支付宝支付
                  </Radio>
                </Radio.Group>
              </div>
              <div className="delivery">
                <div className="deliveryPayment">
                  实付：{this.props.location.state.goodsInfo}
                  <span className="price">
                    ￥
                    {this.props.location.state.goodsInfo
                      ? (
                          goodsInfo.priceH *
                          (goodsInfo.coefficient - 0) *
                          goodsInfo.total
                        ).toFixed(2)
                      : (
                          this.props.location.state.info.proAllPrice
                        ).toFixed(2)}
                  </span>
                </div>
                <div className="deliveryAddress">
                  寄送至：{defaultValueItem.province}
                  {defaultValueItem.city}
                  {defaultValueItem.county}
                  {defaultValueItem.address}
                </div>

                <div className="deliveryRen">
                  收货人：{defaultValueItem.fullName}
                  {defaultValueItem.phone}
                </div>
              </div>
              <div className="orderBottom">
                <div className="backCart">
                  <img src={back} alt="" />
                  <span>返回购物车</span>
                </div>
                <div className="queRenOrderBtn">
                  {this.props.location.state.goodsInfo ? (
                    <Button onClick={this.PlaceTheOrderDirectly}>
                      确认订单
                    </Button>
                  ) : (
                    <Button onClick={this.addCartOrder}>确认订单</Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
