import React, { Component } from 'react';
import './index.scss';
import { Pagination } from 'antd';
export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div className="search">
                <div className="searchGoodsList">
                    {
                        [1, 2, 3, 4, 5,6,5,7,8,9,10,11,12,13,14].map(item => (
                            <div className="searchGoodsItem">
                                <div className="searchGoodsItemImg">
                                    <img src="https://img2.baidu.com/it/u=1180367934,403689269&fm=26&fmt=auto&gp=0.jpg" alt="" />
                                </div>
                                <div className="searchGoodsName">
                                    野生黄芪特级
                                </div>
                                <div className="searchGoodsItemPrice">
                                    <div className="discountedPrice">
                                        ￥ <span className="discountedPriceNum">234.00</span>
                                    </div>  
                                </div>
                                <div className="searchGoodsItemBottom">
                                    <div className="spec">0.5kg/袋</div>
                                    <div className="city">郑州</div>
                                </div>
                            </div>
                        ))
                    }

                </div>
                <Pagination defaultCurrent={1} total={50} />
            </div>
        )
    }
}