import React, { Component } from 'react';
import BannerContent from '../../home/bannerContent';
import GoodsType from '../../home/goodsType';
import './index.scss';

export default class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <BannerContent />
                <GoodsType />
            </>
        );
    }
}
