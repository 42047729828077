import React, { Component } from 'react';
import Header from '../../components/headerContent';
import './index.scss';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { children } = this.props;
    return (
      <div id="home">
        <Header />
        {children}
      </div>
    );
  }
}
