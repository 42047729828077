import React, { Component } from "react";
import "./index.scss";
import request from "../../assets/js/http";
export default class Notice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeInfo: "",
    };
  }
  componentDidMount() {
    request("post", "/api/cms/noticeInfo", {
      id: this.props.location.state.id,
    }).then((res) => {
      console.log(res);
      this.setState({
        noticeInfo: res.data,
      });
    });
  }
  render() {
    const { noticeInfo } = this.state;
    return (
      <div className="noticeW">
        <div className="notice">
          <div className="title">{noticeInfo.title}</div>
          <div className="content">{noticeInfo.content}</div>
        </div>
      </div>
    );
  }
}
