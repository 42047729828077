import React, { Component } from "react";
import Header from "../../components/headerContent";
import "./index.scss";
import cookie from "react-cookies";
import { NavLink,withRouter } from "react-router-dom";
import request from "../../assets/js/http";
 class PersonalCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personalCenterTypeList: [
        {
          name: "个人资料",
          id: "1",
          path: "/personalCenter/personalData",
          sta: true,
        },
        {
          name: "我的订单",
          id: "2",
          path: "/personalCenter/myOrder",
          sta: false,
        },
        {
          name: "店铺信息",
          id: "3",
          path: "/personalCenter/storeInformation",
          sta: false,
        },
        {
          name: "我的收藏",
          id: "4",
          path: "/personalCenter/myFavorite",
          sta: false,
        },
        {
          name: "进货量",
          id: "5",
          path: "/personalCenter/retailPurchases",
          sta: false,
        },
        {
          name: "我的消息",
          id: "6",
          path: "/personalCenter/myMessage",
          sta: false,
        },
        {
          name: "地址管理",
          id: "7",
          path: "/personalCenter/address",
          sta: false,
        },
      ],
      userInfo: [],
    };
  }
  componentDidMount() {
    this.userInfo();
  }
  userInfo() {
    let userId = cookie.load("infoData").id;
    request("post", "/api/member/user/info", {
      userId,
    }).then((res) => {
      this.setState({
        userInfo: res.data,
      });
    });
  }
  goDiscount = () => {
    this.props.history.push('/personalCenter/discount');
    // console.log(this.props);
  };

  render() {
    const { children } = this.props;
    const { personalCenterTypeList, userInfo } = this.state;
    return (
      <div className="personalCenterW">
        <Header />
        <div className="personalCenter">
          <div className="personalCenterLeft">
            <div className="headPortrait">
              <img src={userInfo.avator} alt="" />
            </div>
            <div className="name">{userInfo.name}</div>
            <div className="balance">
              <div className="balanceItem">
                <div className="balanceItemNum">{userInfo.money}</div>
                <div className="balanceItemName">余额(元)</div>
              </div>
              <div className="balanceItem">
                <div className="balanceItemNum">0</div>
                <div className="balanceItemName" onClick={()=>this.goDiscount()}>
                  优惠券
                </div>
              </div>
              <div className="balanceItem">
                <div className="balanceItemNum">{userInfo.score}</div>
                <div className="balanceItemName">积分</div>
              </div>
            </div>
            <div className="heng"></div>
            {
              <div className="linkList">
                {personalCenterTypeList.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={item.id}
                    activeClassName="activeLinkItem"
                    className="linkItem"
                  >
                    <div className="icon"></div>
                    {item.name}
                  </NavLink>
                ))}
              </div>
            }
          </div>
          {children}
        </div>
      </div>
    );
  }
}

export default withRouter(PersonalCenter)