import React, { Component } from "react";
import "./index.scss";
import cookie from "react-cookies";
import del from "../../../assets/image/del.png";
import request from "../../../assets/js/http";
import { Pagination } from "antd";
export default class MyFavorite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 总数
      total: 0,
      //收藏列表
      list: [],
    };
  }
  componentDidMount() {
    this.getShoucang();
  }
  // 获取收藏列表
  getShoucang() {
    request("post", "/api/member/goods/collectList", {
      userId: cookie.load("infoData").id,
      pageNum: "1",
      pageSize: "8",
    }).then((res) => {
      console.log(res);
      this.setState({
        list: res.data.list,
        total: res.data.total,
      });
    });
  }
  goDetais(id){
    this.props.history.push("/home/productDetails/" + id);

  }
 async delete(id,e){
   console.log(e);
   e.stopPropagation();
   const res =  await request('post',"/api/member/goods/collectOrCancel",{
      userId: cookie.load("infoData").id,
      goodsId:id
    })
    this.getShoucang();
  }
  render() {
    const { list, total } = this.state;
    return (
      <div className="myFavorite">
        <div className="myFavoriteList">
          {list.map((item) => (
            <div onClick = {this.goDetais.bind(this,item.goodsId)} className="myFavoriteItem">
              <div className="myFavoriteItemPic">
                <img
                  src={item.imgurl}
                  alt=""
                />
              </div>
              <div className="myFavoriteItemName">{item.name}</div>
              <div className="myFavoriteItemPrice">
                ￥<span className="price">{item.priceF}</span>
              </div>
              <div className="myFavoriteItemBottom">
                <div className="spec">0.5kg/袋</div>
                <div className="city">{item.city}</div>
              </div>
              <div className="myFavoriteItemDelPic" onClick = {this.delete.bind(this,item.goodsId)}>
                <img src={del} alt="" />
              </div>
            </div>
          ))}
        </div>
        <Pagination pageSize="8" defaultCurrent={1} total={total} />
      </div>
    );
  }
}
