import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom"
import { Form, Input, Button, Checkbox, Cascader, message, Popconfirm, Modal } from 'antd';
import city from '../../../assets/js/city'
import cookie from "react-cookies";
// import {
//     ArrowLeftOutlined
// } from '@ant-design/icons';
import './index.scss';
import request from '../../../assets/js/http';
class Biaodan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: city,
            isxiugai: false,          //修改
            isShow: false,
            fullName: '',  //收货人名字
            phone: '',  //收货人手机号
            province: '', //省
            city: '', //市
            country: '', //区
            address: '', //区
            default1: 1, //区
            addressList: '',
            addressId: '',
            setfullName: '',
            setphone: '',
            setprovince: '',
            setcity: '',
            setcountry: '',
            setaddress: '',
            setdefaultValue: '',
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.fullName !== nextState.fullName) {
            return false
        }
        if (this.state.phone !== nextState.phone) {
            return false
        }
        if (this.state.address !== nextState.address) {
            return false
        }
        if (this.state.province !== nextState.province) {
            return false
        }
        if (this.state.city !== nextState.city) {
            return false
        }
        if (this.state.country !== nextState.country) {
            return false
        }
        if (this.state.default1 !== nextState.default1) {
            return false
        }
        return true;
    }
    //添加收货地址
    add = () => {
        const { fullName, phone, address, province, city, country, default1 } = this.state
        let userId = cookie.load("infoData").id;
        if (fullName.replace(/[ ]/g, "").length == 0) {
            message.error('请填写收货人')
        } else if (!phone) {
            message.error('请填写电话')
        } else if (address.replace(/[ ]/g, "").length == 0) {
            message.error('请填写详细地址')
        } else if (!province) {
            message.error('请填写所在地区')
        } else if (!city) {
            message.error('请填写所在地区')
        } else {

            request('post', '/api/member/address/add', {
                userId,
                fullName,
                phone,
                address,
                province,
                city,
                country,
                default: default1
            }).then(res => {
                //////console.log(res);
                this.addressList()
                if (res.code === 0) {
                    setTimeout(() => {
                        message.success('保存成功');
                        this.setState({
                            fullName: '',
                            phone: '',
                            address: '',
                            province: '',
                            city: '',
                            country: '',
                        }, function () {
                            this.setState({
                                fullName: '',
                                phone: '',
                                address: '',
                                province: '',
                                city: '',
                                country: '',
                            })
                        })
                    }, 0);
                } else {
                    message.error('保存失败');
                }
            })
        }
    }
    //修改收货地址信息
    xiugai() {
        const { setfullName, setphone, setaddress, setprovince, setcity, setcountry, setdefaultValue, addressId } = this.state
        let userId = cookie.load("infoData").id;
        request('post', '/api/member/address/change', {
            userId,
            fullName: setfullName,
            phone: setphone,
            address: setaddress,
            province: setprovince,
            city: setcity,
            country: setcountry,
            default: setdefaultValue,
            addressId
        }).then(res => {
            //////console.log(res);
            this.addressList()
            this.setState({
                isxiugai: false
            }, function () {
                this.setState({
                    setfullName: '',
                    setaddress: '',
                    setprovince: '',
                    setcity: '',
                    setcountry: '',
                    default: ''
                })
            })
        })
    }
    addressList() {
        let userId = cookie.load("infoData").id;
        request('post', '/api/member/address/list', {
            userId
        }).then(res => {
            console.log(res);
            this.setState({
                addressList: res.data
            })
        })
    }

    componentDidMount() {
        this.addressList()
    }
    fullName(e) {
        this.setState({
            fullName: e.target.value
        })
    }
    phone(e) {
        this.setState({
            phone: e.target.value
        })
    }
    city(e) {
        this.setState({
            province: e[0],
            city: e[1],
            country: e[2] ? e[2] : ''
        })
    }
    address(e) {
        this.setState({
            address: e.target.value
        })
    }
    setfullName(e) {
        this.setState({
            setfullName: e.target.value
        })
    }
    setphone(e) {
        this.setState({
            setphone: e.target.value
        })
    }
    setcity(e) {
        this.setState({
            setprovince: e[0],
            setcity: e[1],
            setcountry: e[2] ? e[2] : ''
        })
    }
    setaddress(e) {
        this.setState({
            setaddress: e.target.value
        })
    }
    setregdefault = (e) => {
        if (e.target.checked) {
            this.setState({
                setdefaultValue: 1
            })
        } else {
            this.setState({
                setdefaultValue: 1
            })
        }
    }
    //默认地址
    default(e) {
        if (e.target.checked) {
            //////console.log(1);
            this.setState({
                default1: 1
            })
        } else {
            //////console.log(0);
            this.setState({
                default1: 0
            })
        }
    }
    showxiugai = (item) => {
        this.setState({
            isxiugai: true,
            addressId: item.id,
            setfullName: item.fullName,
            setphone: item.phone,
            setprovince: item.province,
            setcity: item.city,
            setcountry: item.county,
            setaddress: item.address,
            setdefaultValue: item.defaultValue
        })
    };

    xiugaiOk = () => {
        this.setState({
            isxiugai: false
        })
    };

    xiugaiCancel = () => {
        this.setState({
            isxiugai: false
        })
    };
    delId(addressId) {
        this.setState({
            addressId
        })
    }
    //删除收货地址
    del(addressId) {
        //////console.log(addressId);
        let userId = cookie.load("infoData").id;
        request('post', '/api/member/address/remove', {
            userId,
            addressId
        }).then(res => {
            //////console.log(res);
            this.addressList()
        })

    }
    //谁为默认地址
    set(addressId) {
        console.log(addressId);
        let userId = cookie.load("infoData").id;
        request('post', '/api/member/address/default', {
            userId,
            addressId
        }).then(res => {
            //////console.log(res);
            this.addressList()
        })
    }
    render() {
        const layout = {
            labelCol: {
                span: 2,
            },
            wrapperCol: {
                span: 9,
            },
        };
        const tailLayout = {
            wrapperCol: {
                offset: 2,
                span: 9,
            },
        };
        const layoutB = {
            labelCol: {
                span: 3,
            },
            wrapperCol: {
                span: 14,
            },
        };
        const tailLayoutB = {
            wrapperCol: {
                offset: 2,
                span: 10,
            },
        };
        let that = this
        ////console.log(addressId);
        const text = '是否确认删除';
        function confirm(e) {
            that.del(that.state.addressId)
            message.success('删除成功');
        }

        const Moren = () => (
            <div className="moren">
                默认地址
            </div>
        )

        const Demo = () => {
            return (
                <Form
                    {...layout}
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Form.Item
                        label="收货人："
                        onChange={(e) => this.fullName(e)}
                        rules={[
                            {
                                message: '请输入姓名!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="电话："
                        onChange={(e) => this.phone(e)}
                        rules={[
                            {
                                message: '请输入手机号!',
                            },
                        ]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <div className="citydiv">
                        <span>所在地区：</span>
                        <Cascader options={this.state.options} onChange={(e) => this.city(e)} className="city" placeholder="请选择" />
                    </div>
                    <div className="xiangxi">
                        <span>详细地址：</span>
                        <textarea onChange={(e) => this.address(e)}
                            name="" id="" cols="66" rows="4" placeholder="请填写街道、门牌号"></textarea>
                    </div>
                    <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox onChange={(e) => this.default(e)}>设为默认地址</Checkbox>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button onClick={() => this.add()} type="primary">
                            保存
                        </Button>
                    </Form.Item>
                </Form>
            );
        };
        return (
            <>
                <div className="mydizhi">
                    <div className="biaoti">
                        地址管理
                    </div>
                    <div className="shouhuobiaoti">
                        新增收货地址

                        <div className="biaodan">
                            <Demo />
                        </div>
                        <div className="biaoge">
                            <div className="tishi">
                                <span>!</span><span>已保存了1条收货地址</span>
                            </div>
                            <div className="shouhuobiaoge">
                                <table className="listTable">
                                    <thead className="listThead">
                                        <tr className="listTr">
                                            <th style={{ width: '144px' }}>收货人</th>
                                            <th style={{ width: '174px' }}>所在地区</th>
                                            <th style={{ width: '342px' }}>详细地址</th>
                                            <th style={{ width: '175px' }}>电话</th>
                                            <th style={{ width: '174px' }}>操作</th>
                                            <th style={{ width: '179px' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody className="listTbody">
                                        {
                                            this.state.addressList && this.state.addressList.map(item => (
                                                <tr key={item.id} className="TbodyTr" >
                                                    <td>{item.fullName}</td>
                                                    <td>{item.province}{item.city}{item.county}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.phone}</td>
                                                    <td>
                                                        <Button type="primary" onClick={() => this.showxiugai(item)}>
                                                            修改
                                                        </Button>
                                                        <span style={{ margin: '0 10px' }}>|</span>

                                                        <Popconfirm
                                                            placement="topRight"
                                                            title={text}
                                                            onConfirm={confirm}
                                                            // onCancel={cancel}
                                                            okText="确认"
                                                            cancelText="取消"
                                                        >
                                                            {/* <a href="#">Delete</a> */}
                                                            <Button onClick={() => this.delId(item.id)} style={{ border: 'none' }}>删除</Button>
                                                        </Popconfirm></td>
                                                    <td onClick={() => this.set(item.id)}> {item.defaultValue == 0 ? <Moren /> : '设为默认'} </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Modal className="xiugaiModal" footer={[]} title="修改地址" visible={this.state.isxiugai} onOk={this.xiugaiOk} onCancel={this.xiugaiCancel} >
                            <div className="biaodan">
                                <Form
                                    {...layoutB}
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                >
                                    <Form.Item
                                        label="收货人："
                                        name="username"
                                        onChange={(e) => this.setfullName(e)}
                                        rules={[
                                            {
                                                message: '请输入姓名!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="电话："
                                        name="phone"
                                        onChange={(e) => this.setphone(e)}
                                        rules={[
                                            {
                                                message: '请输入手机号!',
                                            },
                                        ]}
                                    >
                                        <Input type="number" />
                                    </Form.Item>
                                    <div className="citydiv">
                                        <span>所在地区：</span>
                                        <Cascader className="city" options={this.state.options} onChange={(e) => this.setcity(e)} placeholder="请选择" />
                                    </div>
                                    <div className="xiangxi">
                                        <span>详细地址：</span>
                                        <textarea
                                            onChange={(e) => this.setaddress(e)}
                                            name="" id="" cols="66" rows="4" placeholder="请填写街道、门牌号"></textarea>
                                    </div>
                                    <Form.Item {...tailLayoutB} name="remember" valuePropName="checked">
                                        <Checkbox onChange={(e) => this.setregdefault(e)}>设为默认地址</Checkbox>
                                    </Form.Item>


                                    <Form.Item {...tailLayoutB}>
                                        <Button onClick={() => this.xiugai()} type="primary">
                                            确认修改
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Modal>
                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(Biaodan)