import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import request from "../../../assets/js/http";
import { Pagination } from "antd";
import homeSpecialBanner from "../../../assets/image/home_special.png";
export default class HomeSpecial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialList: [],
    };
  }
  componentDidMount() {
    this.specialList();
  }
  specialList() {
    request("post", "/api/goods/specialDrug", {
      status: 0,
      pageNum: 1,
      pageSize: 10,
    }).then((res) => {
      this.setState({
        specialList: res.data,
        
      });
    });
  }
  specialPagination = (pageNum) => {
    request("post", "/api/goods/specialDrug", {
      status: 0,
      pageNum,
      pageSize: 10,
    }).then((res) => {
      this.setState({
        specialList: res.data,
      });
    });
  };
  render() {
    const { specialList } = this.state;
    return (
      <div className="homeSpecial">
        <div className="homeSpecialBanner">
          <img src={homeSpecialBanner} alt="" />
        </div>
        <div className="homeSpecialGoodsList">
          {specialList.list &&
            specialList.list.map((item) => (
              <Link
                target="_blank"
                key={item.goodsId}
                className="LinkJump"
                to={"/home/productDetails/" + item.goodsId}
              >
                <div className="homeSpecialGoodsItem">
                  <div className="homeSpecialGoodsItemImg">
                    <img src={item.imgurl} alt="暂无图片" />
                  </div>
                  <div className="homeSpecialGoodsName">{item.name}</div>
                  <div className="homeSpecialGoodsItemPrice">
                    <div className="discountedPrice">
                      ￥{" "}
                      <span className="discountedPriceNum">{item.priceH}</span>
                      <span className="discountPriceNum">￥{item.priceF}</span>
                    </div>
                  </div>
                  <div className="homeSpecialGoodsItemBottom">
                    {/* <div className="spec">{item.unit}</div> */}
                    <div className="describe">特价</div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <Pagination
          pageSize="10"
          current={specialList.pageNum}
          total={specialList.total}
          onChange={this.specialPagination}
        />
      </div>
    );
  }
}
