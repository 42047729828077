import React, { Component } from 'react';
import './index.scss';
import { Button } from 'antd';
import cookie from 'react-cookies'
import request from '../../../assets/js/http';


export default class PersonalData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo:[]
        }
    }
    componentDidMount(){
        this.userInfo()
    }
    userInfo() {
        let userId = cookie.load('infoData').id
        request('post', '/api/member/user/info', {
            userId
        }).then(res => {
            this.setState({
                userInfo: res.data
            })
        })
    }

    render() {
        const {userInfo} = this.state
        return (
            <div className="personalData">
                <div className="personalDataTitle">
                    账号信息
                </div>
                <div className="personalDataFrom">
                    <div className="personalDataFromItem">
                        <div className="personalDataFromItemName">
                            头像：
                        </div>
                        <div className="touXiangImg">
                            <img src={userInfo.avator} alt="" />
                            <span>点击可修改</span>
                        </div>
                    </div>
                    <div className="personalDataFromItem">
                        <div className="personalDataFromItemName">
                            昵称：
                        </div>
                        <div className="personalDataFromItemInput">
                            <input value={userInfo.name} type="text" />
                        </div>
                    </div>
                    <div className="personalDataFromItem">
                        <div className="personalDataFromItemName">
                            性别：
                        </div>
                        <div className="personalDataFromItemSex">
                            <input type="radio" name="sex" value={0} /> <span className="sex">男</span>
                            <input type="radio" name="sex" value={1} /> <span className="sex">女</span>
                        </div>
                    </div>
                    <div className="personalDataFromItem">
                        <div className="personalDataFromItemName">
                            手机号：
                        </div>
                        <div className="personalDataFromItemInput">
                            <input type="text" value={userInfo.phone} />
                        </div>
                    </div>
                    <div className="personalDataFromItem">
                        <div className="personalDataFromItemName">
                            单位名称：
                        </div>
                        <div className="personalDataFromItemInput">
                            <input type="text" />
                        </div>
                    </div>
                    <div className="personalDataFromItem">
                        <div className="personalDataFromItemName">
                            收货人：
                        </div>
                        <div className="personalDataFromItemInput">
                            <input type="text" />
                        </div>
                    </div>
                    <div className="personalDataFromItem">
                        <div className="personalDataFromItemName">
                            收货地址：
                        </div>
                        <div className="personalDataFromItemInput">
                            <input type="text" />
                        </div>
                    </div>
                    <Button type="primary">提交</Button>
                </div>
            </div>
        )
    }
}