import React from 'react';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd'
import { Switch, Route, Redirect, Router } from 'react-router-dom';

import HerderTop from './components/headerTop'          //公共头部
import FooterBottom from './components/footerBottom'          //公共底部
import Home from './pages/home'          //首页
import HomePage from './pages/home/homePage'          //首页
import HomeSpecial from './pages/home/homespecial'          //特价药品
import HomeTimeLimit from './pages/home/hometimelimit'          //限时抢购
import HomeDiscounts from './pages/home/homediscounts'          //满减优惠
import HomeNewProduct from './pages/home/homenewproduct'          //新品推荐
import SearchGoods from './pages/search'          //搜索
import Notice from './pages/notice'          //公告
import ProductDetails from './pages/productDetails'          //商品详情
import PersonalCenter from './pages/personalCenter'          //个人中心
import PersonalData from './pages/personalCenter/personalData'          //个人资料
import MyOrder from './pages/personalCenter/myOrder'          //我的订单
import StoreInformation from './pages/personalCenter/storeInformation'          //店铺信息
import MyFavorite from './pages/personalCenter/myFavorite'          //我的收藏
import Discount from './pages/personalCenter/discount'          //优惠券

import RetailPurchases from './pages/personalCenter/retailPurchases'          //进货量
import MyMessage from './pages/personalCenter/myMessage'          //我的消息
import Address from './pages/personalCenter/address'          //地址管理
import Balance from './pages/personalCenter/balance'          //余额
import Integral from './pages/personalCenter/integral'          //积分
import ShoppingCart from './pages/shoppingCart'          //购物车
import ConfirmOrder from './pages/confirm'          //确认订单
import OrderDetails from './pages/orderDetails'          //订单详情
import Boutique from './pages/home/boutique'          //问价
import Payment from './pages/Payment'          //支付


// const { Header, Footer, Content } = Layout;
export default class IRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <ConfigProvider locale={zhCN}>
                    <HerderTop />
                    <Switch>
                        <Route
                            path="/home"
                            render={() => (
                                <Home>
                                    <Switch>
                                        <Route path="/home/index" component={HomePage} />
                                        <Route path="/home/homeSpecial" component={HomeSpecial} />
                                        <Route path="/home/homeTimeLimit" component={HomeTimeLimit} />
                                        <Route path="/home/homeDiscounts" component={HomeDiscounts} />
                                        <Route path="/home/homeNewProduct" component={HomeNewProduct} />
                                        <Route path="/home/searchGoods" component={SearchGoods} />
                                        <Route path="/home/notice" component={Notice} />
                                        <Route path="/home/productDetails/:id" component={ProductDetails} />
                                        <Route path="/home/shoppingCarts" component={ShoppingCart} />
                                        <Route path="/home/confirmOrder" component={ConfirmOrder} />
                                        <Route path="/home/orderDetails" component={OrderDetails} />
                                        <Route path="/home/boutique/:id" component={Boutique} />
                                        <Route path="/home/payment" component={Payment} />
                                        <Redirect to="/home/index" />
                                    </Switch>
                                </Home>
                            )}
                        />
                        <Route
                            path="/personalCenter"
                            render={() => (
                                <PersonalCenter>
                                    <Switch>
                                        <Route path="/personalCenter/personalData" component={PersonalData} />
                                        <Route path="/personalCenter/myOrder" component={MyOrder} />
                                        <Route path="/personalCenter/storeInformation" component={StoreInformation} />
                                        <Route path="/personalCenter/myFavorite" component={MyFavorite} />
                                        <Route path="/personalCenter/retailPurchases" component={RetailPurchases} />
                                        <Route path="/personalCenter/myMessage" component={MyMessage} />
                                        <Route path="/personalCenter/balance" component={Balance} />
                                        <Route path="/personalCenter/integral" component={Integral} />
                                        <Route path="/personalCenter/address" component={Address} />
                                        <Route path="/personalCenter/discount" component={Discount} />
                                        <Redirect to="/personalCenter/personalData" />
                                    </Switch>
                                </PersonalCenter>
                            )}
                        />
                        <Redirect to="/home" />
                    </Switch>

                    <FooterBottom />
                </ConfigProvider>
            </div>
        )
    }
}