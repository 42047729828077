import React, { Component } from 'react';
import './index.scss';
import { Modal } from 'antd';   
import loginLogo from '../../assets/image/loginLogo.png'
export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginModal: props.loginModal
        }
        console.log(props);
        // setState({
        //     loginModal:props.loginModal
        // })
        
    }
    componentDidMount(){
        console.log(this);
    }
    showLoginModal = () => {
        this.setState({
            loginModal: true
        })
        console.log(this);
    };

    handleLoginOk = () => {
        this.setState({
            loginModal: false
        })
        console.log(this);
    };

    handleLoginCancel = () => {
        this.setState({
            loginModal: false
        })
    };

    render() {

        return (
            <>
                <Modal title={[<img src={loginLogo} alt="" />]} visible={this.state.loginModal} onOk={this.handleLoginOk} onCancel={this.handleLoginCancel}>
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                    <p>Some contents...</p> 
                </Modal>
            </>
        )
    }
}