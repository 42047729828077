import React, { Component } from "react";
import "./index.scss";
import daizhifu from "../../assets/image/daizhifu.png";
import daishouhuo from "../../assets/image/daishouhuo.png";
import request from "../../assets/js/http";
import cookie from "react-cookies";
import { message } from "antd";

export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {},
      productList: [],
      zongMsg: {},
    };
  }
  componentDidMount() {
    this.getMsg();
  }
  getMsg() {
    console.log(this.props.location.state.orderId);
    request("post", "/api/order/order/info", {
      orderId: this.props.location.state.orderId,
      userId: cookie.load("infoData").id,
    }).then((res) => {
      console.log(res);
      this.setState({
        address: res.data.address,
        productList: res.data.detailList,
        zongMsg: res.data,
      });
    });
  }
  payment() {
    console.log(this.state.zongMsg);
    this.props.history.push({
      pathname: "/home/payment",
      state: { data: this.state.zongMsg },
    });
  }
  zhuangtai() {
    if (this.state.zongMsg.status === 0) {
      return <span>待支付</span>;
    } else if (this.state.zongMsg.status === 1) {
      return <span>待发货</span>;
    } else if (this.state.zongMsg.status === 2) {
      return <span>待收货</span>;
    }
  }
  async delete() {
    let userId = cookie.load("infoData").id;
    console.log(userId);
    let orderId = this.props.location.state.orderId;
    const res = await request("post", "/api/order/order/cancel", {
      userId,
      orderId,
    });
    if (res.code == 0) {
      message.success("取消成功");
      if (cookie.load("infoData")) {
        this.props.history.push({ pathname: "/personalCenter/myOrder" });
      } else {
        message.warning("请先登录");
      }
    }
  }
  tixing(){
    message.success('提醒成功')
  }
  async shouhuo(){
    let userId = cookie.load("infoData").id;
    console.log(userId);
    let orderId = this.props.location.state.orderId;
    const res = await request("post", "/api/order/order/confirm", {
      userId,
      orderId,
    });
    if (res.code == 0) {
      message.success("已确认收货");
      if (cookie.load("infoData")) {
        this.props.history.push({ pathname: "/personalCenter/myOrder" });
      } else {
        message.warning("请先登录");
      }
    }
  }
  zhuangtai1() {
    if (this.state.zongMsg.status === 0) {
      return (
        <div style={{ display: "flex" }}>
          <div
            className="footer"
            onClick={this.payment.bind(this)}
            style={{ marginLeft: 10, cursor: "pointer" }}
          >
            付款
          </div>
          <div
            onClick={this.delete.bind(this)}
            className="footer"
            style={{ marginLeft: 10, cursor: "pointer" }}
          >
            取消订单
          </div>
        </div>
      );
    } else if (this.state.zongMsg.status === 1) {
      return (
        <div style={{ display: "flex" }}>
          <div className="footer" onClick = {
            this.tixing.bind(this)
          } style={{ marginLeft: 10, cursor: "pointer" }}>
            提醒发货
          </div>
        </div>
      );
    } else if (this.state.zongMsg.status === 2) {
      return (
        <div style={{ display: "flex" }}>
          <div className="footer" onClick = {this.shouhuo.bind(this)} style={{ marginLeft: 10, cursor: "pointer" }}>
            确认收货
          </div>
        </div>
      );
    }
  }
  render() {
    const { address, productList, zongMsg } = this.state;
    return (
      <div className="orderDetailsW">
        <div className="orderDetails">
          <div className="orderDetailsTop">
            <div className="orderInfo">
              <div className="orderInfoTitle">订单信息</div>
              <div className="orderInfoContent">
                <div className="orderInfoContentItem">
                  <span className="orderInfoContentItemName">收货地址：</span>
                  <span className="orderInfoContentItemValue">
                    {address.receiverName}，{address.receiverPhone}，
                    {address.receiverProvince +
                      address.receiverCity +
                      address.receiverCounty +
                      address.receiverAddress}
                  </span>
                </div>

                <div className="orderInfoContentItem">
                  <span className="orderInfoContentItemName">订单编号:</span>
                  <span
                    className="orderInfoContentItemValue"
                    style={{ paddingLeft: 10 }}
                  >
                    {address.orderId}
                  </span>
                </div>

                <div className="orderInfoContentItem">
                  <span className="orderInfoContentItemName">创建时间：</span>
                  <span className="orderInfoContentItemValue">
                    {address.createAt}
                  </span>
                </div>

                <div className="orderInfoContentItem">
                  <span className="orderInfoContentItemName">付款时间：</span>
                  <span className="orderInfoContentItemValue">
                    {address.updateAt}
                  </span>
                </div>
              </div>
            </div>
            <div className="orderType">
              <div className="orderTypebox1">
                <div>
                  <img src="" alt="" />
                </div>
                <div className="zhuangtai">订单状态：{this.zhuangtai()}</div>
                <div className="wuliu">
                  物流：中通快递运单号:{zongMsg.shippingCode}
                  您已在郑州市学府花园店完成取件，感谢使用菜鸟驿站，期待再次为您服务。
                </div>
                {this.zhuangtai1()}
              </div>
            </div>
          </div>
          <div className="orderDetailsBottom">
            <div className="orderDetailsBottomTitle">商品信息</div>
            <div className="orderDetailsMsg">
              <table className="orderDetailsMsgTable">
                <thead className="orderDetailsMsgTable_thead">
                  <tr className="orderDetailsMsgTable_thead_tr">
                    <th>商品图片</th>
                    <th>商品名称</th>
                    <th>规格</th>
                    <th>数量</th>
                    <th>单价</th>
                    <th>小计</th>
                  </tr>
                </thead>
                <tbody className="orderDetailsMsgTable_tbody">
                  {productList.map((item) => (
                    <tr className="orderDetailsMsgTable_tbody_tr">
                      <td>
                        <img src={item.imgurl} alt="" />
                      </td>
                      <td>{item.goodsName}</td>
                      <td>{item.spec}</td>
                      <td>{item.total}</td>
                      <td>￥{item.priceH}</td>
                      <td>￥{item.priceH}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="orderDetailsMsgPrice">
              <div className="orderDetailsMsgPriceItem">
                <span className="orderDetailsMsgPriceItemName">优惠：</span>
                <span className="orderDetailsMsgPriceItemValue">
                  ￥{zongMsg.discountMoney}
                </span>
              </div>
              <div className="orderDetailsMsgPriceItem">
                {/* <span className="orderDetailsMsgPriceItemName">运费：</span>
                <span className="orderDetailsMsgPriceItemValue">￥10.00</span> */}
              </div>
              <div className="orderDetailsMsgPriceItem">
                <span className="orderDetailsMsgPriceItemName">商品总价：</span>
                <span className="orderDetailsMsgPriceItemValue">
                  ￥{zongMsg.allPrice}
                </span>
              </div>
              <div className="orderDetailsMsgPriceItem">
                <span className="price">实付：</span>
                <span className="num">￥{zongMsg.payment}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
