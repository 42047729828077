import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import loginLogo from "../../assets/image/loginLogo.png";
import { Form, Modal, Input, Button, message } from "antd";
import Login from "../login";
import "./index.scss";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import request from "../../assets/js/http";
import cookie from "react-cookies";

class HeaderTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModal: false,
      phone: "",
      password: "",
    };
  }
  componentDidMount() {}
  phone(e) {
    this.setState({
      phone: e.target.value,
    });
  }
  password(e) {
    this.setState({
      password: e.target.value,
    });
  }
  userLogin = () => {
    const { phone, password } = this.state;
    request("post", "/api/member/user/login", {
      phone: phone,
      password: password,
    }).then((res) => {
      if (res.code === 0) {
        message.success(res.msg);
        let data = res.data;
        let inFifteenMinute = new Date(
          new Date().getTime() + 168 * 3600 * 1000
        ); //七天
        cookie.save("infoData", data, { expires: inFifteenMinute, path: "/" });
        this.handleLoginOk();
      } else {
        message.warning(res.msg);
      }
    });
  };
  showLoginModal = () => {
    this.setState({
      loginModal: true,
    });
  };

  handleLoginOk = () => {
    this.setState({
      loginModal: false,
    });
  };

  handleLoginCancel = () => {
    this.setState({
      loginModal: false,
    });
  };
  goDingdan() {
    console.log(123);
    this.props.history.push("/personalCenter/myOrder");
  }
  render() {
    const { loginModal } = this.state;
    return (
      <>
        <div className="headerTopW">
          <div className="headerTop">
            <div className="headerTitle">专业饮片批发平台</div>
            <div className="headerNav">
              <div className="regLogin" onClick={() => this.showLoginModal()}>
                {cookie.load("infoData")
                  ? cookie.load("infoData").name
                  : "登录"}
              </div>
              <Login loginModal={loginModal} />|
              <div className="order" onClick={this.goDingdan.bind(this)}>
                订单管理
              </div>
              |<div className="phone">手机端</div>
            </div>
          </div>
        </div>
        <Modal
          className="loginModal"
          footer={[]}
          title={[<img src={loginLogo} alt="" />]}
          visible={this.state.loginModal}
          onOk={this.handleLoginOk}
          onCancel={this.handleLoginCancel}
        >
          <Form name="basic" initialValues={{ remember: true }}>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <Input
                onChange={(e) => this.phone(e)}
                placeholder="请输入手机号"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password
                onChange={(e) => this.password(e)}
                placeholder="请输入密码"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                onClick={() => this.userLogin()}
                htmlType="submit"
              >
                确认登录
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
export default withRouter(HeaderTop);
