import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import { withRouter } from "react-router-dom"
import './index.scss';
// import img from '../../static/img/del_home_lun.png';
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
    }

    render() {
        return (
            <>
                <div className="footerBottomW">
                    <div className="footerBottom">
                        <div className="content">
                            <p>咨询请拨打客服热线：<span>4001899114</span>  <span>邮箱 kfb@99114.com</span> </p>
                            <p>增值电信业务经营许可证：***************</p>
                            <p>版权所有：河南小牛信息科技有限公司所有</p>
                            <p>豫ICP证060961号 网站备案号京ICP备08102099号-148</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Footer