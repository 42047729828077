import React, { Component } from 'react';
import './index.scss';
import { Radio, Button, Modal, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { withRouter } from "react-router-dom"
import cookie from 'react-cookies';
import QRCode from 'qrcode.react';
import request from '../../assets/js/http';
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      payinfo: ''
    };
  }
  componentDidMount() {
    this.setState({
      data: this.props.location.state.data
    })
    this.payment()
    this.backInterval = setInterval(() => {
    // this.tick()
    }, 2000)
    setInterval(this.tick(), 2000)
  }
  payment() {
    let userId = cookie.load("infoData").id;
    console.log(this.props.location.state.data.payType);
    request('post', '/api/tongLianPay/testPay', {
      orderId: this.props.location.state.data.id,
      payType: this.props.location.state.data.payType=='0'?'W01':'A01'
      // payType:'W01'
    }).then(res => {
      console.log(res);
      if (res.code == 0) {
        // let payinfo = 
        this.setState({
          payinfo: res.data.payinfo
        })
      }
    })
  }

  //已完成
  tick() {
    console.log(this.props);
    if (this.props.history.location.pathname!='/home/payment') {
      clearInterval(this.backInterval)
    }
    let orderId = this.props.location.state.data.id
    let userId = cookie.load("infoData").id;
    request('post', '/api/order/order/info', {
      userId,
      orderId,
    }).then(res => {
      if (res.code == 0) {
        if (res.data.status == 1) {
          this.props.history.push({ pathname: '/personalCenter/myOrder', state: { index: 1 } });
          clearInterval(this.backInterval)
        }
      }
     
      // if (res.code == 500) {
      //   this.props.history.push({ pathname: '/myProfile/order', state: { type: 2 } })
      // } else {
      //   if (res.data.status == 1) {
      //     message.warning('未完成支付')
      //   } else {
      //     this.props.history.push({ pathname: '/myProfile/orderdetails', state: { orderId } })
      //   }
      // }

    })
  }
  componentWillMount() {
    clearInterval(this.backInterval)
  }
  render() {
    const { data, payinfo } = this.state
    console.log(payinfo);
    return (
      <div className="payment">
        <div className="top">
          {/* <CheckCircleFilled style={{ fontSize: '42px', color: '#C33529' }} /> */}
          <div className="left">
            <div>订单号：{data.orderSn}</div>
            <div>买家昵称：{data.buyerNick}</div>
          </div>
          <div className="right">
            {data.payment}<span>元</span>
          </div>
        </div>
        <div className="QRCode">
          <div className="price">
            {data.payment}
          </div>
          <div className="QRCodeImg">
            <QRCode
              value={payinfo && payinfo}  //value参数为生成二维码的链接
              size={264} //二维码的宽高尺寸
              fgColor="#000000"  //二维码的颜色
            />
            {/* <img src={this.state.payinfo} alt="" /> */}
          </div>
          <div className="tips">
            <p>打开手机{this.props.location.state.data.payType==0?'微信':'支付宝'}</p>
            <p>扫一扫即可付款</p>
          </div>
          {/* <div className="tick">已完成支付</div> */}
        </div>
      </div>
    );
  }
}
export default withRouter(Payment)