import React, { Component } from "react";
import "./index.scss";

import request from "../../../assets/js/http";
export default class Discount extends Component {
  constructor() {
    super();
    this.state = {
      index: "",
    };
  }

  change(index) {
    console.log(index);
    this.setState({
      index: index,
    });
  }

  render() {
    const { index } = this.state;
    const youhuiquan = () => {
      if (index === 0) {
        return (
          <div className="youhuiquan">
            <div className="youhuiquan_box">
              <div className="youhuiquan_box1">
                <div className="box1">
                  <div className="price">￥100</div>
                  <div className="lidu">满100减5</div>
                </div>
                <div className="box2">
                  <div className="box2_son">未使用</div>
                  <div className="box2_son1">
                    有效期：2019-12-29 至 2020-12-12
                  </div>
                </div>
              </div>
              <div className="guize">使用规则：适用整个订单</div>
            </div>
          </div>
        );
      } else if (index === 1) {
        return (
          <div className="youhuiquan">
            <div className="youhuiquan_box">
              <div className="youhuiquan_box1" id="shixiao">
                <div className="box1">
                  <div className="price">￥100</div>
                  <div className="lidu">满100减5</div>
                </div>
                <div className="box2">
                  <div className="box2_son">未使用</div>
                  <div className="box2_son1">
                    有效期：2019-12-29 至 2020-12-12
                  </div>
                  <div id="box3"></div>
                </div>
              </div>
              <div className="guize">使用规则：适用整个订单</div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="youhuiquan">
            <div className="youhuiquan_box">
              <div className="youhuiquan_box1" id="guoqi">
                <div className="box1">
                  <div className="price">￥100</div>
                  <div className="lidu">满100减5</div>
                </div>
                <div className="box2">
                  <div className="box2_son">未使用</div>
                  <div className="box2_son1">
                    有效期：2019-12-29 至 2020-12-12
                  </div>
                </div>
              </div>
              <div className="guize">使用规则：适用整个订单</div>
            </div>
          </div>
        );
      }
    };
    return (
      <div className="father">
        <div className-="title">我的优惠券</div>

        <div className="zongFen">
          <div className="fenlei" onClick={this.change.bind(this, 0)}>
            未使用
          </div>
          <div className="fenlei" onClick={this.change.bind(this, 1)}>
            已使用
          </div>
          <div className="fenlei" onClick={this.change.bind(this, 2)}>
            已过期
          </div>
        </div>
        {
          youhuiquan()
        }
      </div>
    );
  }
}
