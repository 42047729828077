import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import request from "../../../assets/js/http";
import { Pagination } from "antd";
import homeNewProductBanner from "../../../assets/image/home_newproduct.png";
export default class HomeNewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newProductList: [],
    };
  }
  componentDidMount() {
    this.newProductList();
  }
  newProductList() {
    request("post", "/api/goods/specialDrug", {
      status: 1,
      pageNum: 1,
      pageSize: 10,
    }).then((res) => {
      console.log(res);
      this.setState({
        newProductList: res.data,
      });
    });
  }
  newProductPagination = (pageNum) => {
    request("post", "/api/goods/specialDrug", {
      status: 1,
      pageNum,
      pageSize: 10,
    }).then((res) => {
      this.setState({
        newProductList: res.data,
      });
    });
  };
  render() {
    const { newProductList } = this.state;
    return (
      <div className="homeNewProduct">
        <div className="homeNewProductBanner">
          <img src={homeNewProductBanner} alt="" />
        </div>
        <div className="homeNewProductGoodsList">
          {newProductList.list &&
            newProductList.list.map((item) => (
              <Link
                target="_blank"
                key={item.goodsId}
                className="LinkJump"
                to={"/home/productDetails/" + item.goodsId}
              >
                <div className="homeNewProductGoodsItem">
                  <div className="homeNewProductGoodsItemImg">
                    <img src={item.imgurl} alt="暂无图片" />
                  </div>
                  <div className="homeNewProductGoodsName">{item.name}</div>
                  <div className="homeNewProductGoodsItemPrice">
                    <div className="discountedPrice">
                      ￥{" "}
                      <span className="discountedPriceNum">
                        {item.priceH}
                        {item.isYinpian == 1 ? "/kg" : ""}
                      </span>
                      {/* <span className="discountPriceNum">￥899.00</span> */}
                    </div>
                  </div>
                  <div className="homeNewProductGoodsItemBottom">
                    <div className="spec">{item.unit}</div>
                    <div className="city">{item.city}</div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <Pagination
          pageSize="10"
          current={newProductList.pageNum}
          total={newProductList.total}
          onChange={this.newProductPagination}
        />
      </div>
    );
  }
}
