import React, { Component } from "react";
import { DatePicker, Space, ConfigProvider, Input, Pagination } from "antd";
import moment from "moment";
import "moment/locale/zh-cn";
import zhCN from "antd/es/locale/zh_CN";
import request from "../../../assets/js/http";
import cookie from "react-cookies";

import "./index.scss";
const dateFormat = "YYYY/MM/DD";
export default class RetailPurchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      total: 0,
    };
  }
  setCurrentIndex(id) {
    this.setState({
      currentIndex: id,
    });
  }

  componentDidMount() {
    this.getList();
  }
  // 获取进货量列表
  getList() {
    request("post", "/api/member/getUserOrderStatisticsList", {
      userId: cookie.load("infoData").id,
      flag: "1",

    }).then((res) => {
      console.log(res);
      this.setState({
        list: res.data.goodsInfo.list,
        total: res.data.goodsInfo.total,
      });
    });
  }
  onChange(e) {
    console.log(e);
    request("post", "/api/member/getUserOrderStatisticsList", {
      userId: cookie.load("infoData").id,
      flag: "1",

    }).then((res) => {
      console.log(res);
      // this.setState({
      //   list: res.data.goodsInfo.list,
      //   total: res.data.goodsInfo.total,
      // });
    });
  }
  render() {
    const { list, total } = this.state;

    return (
      <div className="retailPurchases">
        <div className="retailPurchasesTop">
          <div className="retailPurchasesTop_left">
            <div className="stockPrice">
              进货金额：<span className="price">￥2345.00</span>
            </div>
            <div className="stockNumber">进货数量：3452</div>
          </div>
          <div className="retailPurchasesTop_Right">
            <Space direction="vertical" size={12}>
              <span>进货时间</span>
              <ConfigProvider locale={zhCN}>
                <DatePicker
                  defaultValue={moment("2015/01/01", dateFormat)}
                  format={dateFormat}
                />
              </ConfigProvider>
            </Space>
          </div>
        </div>

        <div className="retailPurchasesContent">
          <div className="retailPurchasesContentTable">
            <table className="table">
              <thead className="tableThead">
                <tr className="theadTr">
                  <th>商品名称</th>
                  <th>规格</th>
                  <th>产地</th>
                  <th>数量</th>
                  <th>商品单价</th>
                  <th>进货日期</th>
                  <th>小计</th>
                </tr>
              </thead>
              <tbody className="tableTbody">
                {list.map((item) => (
                  <>
                    <tr className="tbodyTr">
                      <td>{item.name}</td>
                      <td>{item.spec}</td>
                      <td>{item.city}</td>
                      <td>{item.total}</td>
                      <td>￥{item.priceH}</td>
                      <td>{item.createAt}</td>
                      <td>￥{item.allMoney}</td>
                    </tr>
                    <div className="gap"></div>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="retailPurchasesTopFooter">
          <Pagination
            pageSize="4"
            onChange={this.onChange.bind(this)}
            defaultCurrent={1}
            total={total}
          />
        </div> */}
      </div>
    );
  }
}
