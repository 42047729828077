import React, { Component } from 'react';
import './index.scss';
import { Upload, Modal ,Button} from 'antd';
export default class StoreInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imgList: []
        }
    }
    //上传图片
    uploadhandleChange = ({ file, fileList }) => {
        // ////console.log(JSON.stringify(file)); // file 是当前正在上传的 单个 img
        // ////console.log(JSON.stringify(fileList)); // fileList 是已上传的全部 img 列表
        ////console.log(file);
        ////console.log(fileList);

        // 【重要】将 图片的base64替换为图片的url。 这一行一定不会能少。
        // 图片上传成功后，fileList数组中的 thumbUrl 中保存的是图片的base64字符串，这种情况，导致的问题是：图片上传成功后，点击图片缩略图，浏览器会会卡死。而下面这行代码，可以解决该bug。
        fileList.forEach(imgItem => {
            if (imgItem && imgItem.status == 'done' && imgItem.response && imgItem.response.imgUrl) {
                imgItem.thumbUrl = imgItem.response.imgUrl;
            }
        });

        if (file.status === 'done') {
            let filesList = []
            fileList.map(item => {
                ////console.log(item.response.data);
                filesList.push(item.response.data)
            })
            ////console.log(filesList);
            setTimeout(() => {
                this.setState({
                    imagesPath: filesList
                })
            }, 0);
        }
        setTimeout(() => {
            this.setState({
                imgList: fileList,
            });
        }, 0);
    };
    handleCancel = () => setTimeout(() => {
        this.setState({ previewVisible: false })
    }, 0);;

    handlePreview = file => {
        setTimeout(() => {
            this.setState({
                previewImage: file.url || file.thumbUrl,
                previewVisible: true,
            });
        }, 0);
    };

    handleBeforeUpload = file => {
        //限制图片 格式、size、分辨率
        const isJPG = file.type === 'image/jpeg';
        const isJPEG = file.type === 'image/jpeg';
        const isGIF = file.type === 'image/gif';
        const isPNG = file.type === 'image/png';
        if (!(isJPG || isJPEG || isGIF || isPNG)) {
            Modal.error({
                title: '只能上传JPG 、JPEG 、GIF、 PNG格式的图片~',
            });
            return;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Modal.error({
                title: '超过2M限制，不允许上传~',
            });
            return;
        }
        return (isJPG || isJPEG || isGIF || isPNG) && isLt2M && this.checkImageWH(file);
    };

    //返回一个 promise：检测通过则返回resolve；失败则返回reject，并阻止图片上传
    checkImageWH(file) {
        return new Promise(function (resolve, reject) {
            let filereader = new FileReader();
            filereader.onload = e => {
                let src = e.target.result;
                const image = new Image();
                image.onload = function () {
                    // 获取图片的宽高，并存放到file对象中
                    ////console.log('file width :' + this.width);
                    ////console.log('file height :' + this.height);
                    file.width = this.width;
                    file.height = this.height;
                    resolve();
                };
                image.onerror = reject;
                image.src = src;
            };
            filereader.readAsDataURL(file);
        });
    }
    render() {
        const uploadButton = (
            <div>
                {/* <Icon type="plus" /> */}
                <div className="ant-upload-text" style={{ fontSize: '20px' }} >上传图片</div>
            </div>
        );
        return (
            <div className="storeInformation">
                <div className="storeInformationTitle">
                    店铺信息
                </div>
                <div className="storeInformationFrom">
                    <div className="storeInformationFromItem">
                        <div className="storeInformationFromItemName">
                            店铺名称：
                        </div>
                        <div className="storeInformationFromItemInput">
                            <input placeholder="请输入店铺名称" type="text" />
                        </div>
                    </div>
                    <div className="storeInformationFromItem">
                        <div className="storeInformationFromItemName">
                            店铺地址：
                        </div>
                        <div className="storeInformationFromItemInput">
                            <input placeholder="请输入店铺地址" type="text" />
                        </div>
                    </div>
                    <div className="storeInformationFromItem">
                        <div className="storeInformationFromItemName">
                            法人姓名：
                        </div>
                        <div className="storeInformationFromItemInput">
                            <input placeholder="请输入法人姓名" type="text" />
                        </div>
                    </div>
                    <div className="storeInformationFromItem">
                        <div className="storeInformationFromItemName">
                            联系电话：
                        </div>
                        <div className="storeInformationFromItemInput">
                            <input placeholder="请输入联系电话" type="text" />
                        </div>
                    </div>
                    <div className="storeInformationFromItem">
                        <div className="storeInformationFromItemUploadName">
                            营业执照照片上传：
                        </div>
                        <Upload
                            action="http://1.116.154.35:25921/clife-boot-api-app/api/cms/common/upload" // 这个是图片上传的接口请求，实际开发中，要替换成你自己的业务接口
                            listType="picture-card"
                            fileList={this.state.imgList}
                            onPreview={this.handlePreview} // 点击图片缩略图，进行预览
                            beforeUpload={this.handleBeforeUpload} // 上传之前，对图片的格式做校验，并获取图片的宽高
                            onChange={this.uploadhandleChange} // 每次上传图片时，都会触发这个方法
                        >
                            {this.state.imgList.length >= 6 ? null : uploadButton}
                        </Upload>
                    </div>
                    <Button>提交</Button>
                </div>
            </div>
        )
    }
}