import React, { Component } from "react";
import "./index.scss";
import BigImg from "./bigImg";
import shoucang from "../../assets/image/shoucang.png";
import shoucang2 from "../../assets/image/shoucang2.png";
import request from "../../assets/js/http";
import cookie from "react-cookies";
import { message } from "antd";
import { Link } from "react-router-dom";

export default class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productId: "",
      detailsImg: "",
      goodsId: "",
      sellList: [],
      tuisellList: [],
      isYinPian: 0,
      // goodsId:this.props.match(),
      buyerLookList: [
        {
          url: "https://img0.baidu.com/it/u=2529224896,2406165372&fm=11&fmt=auto&gp=0.jpg",
          id: 0,
        },
        {
          url: "https://img0.baidu.com/it/u=2230965783,1569785427&fm=26&fmt=auto&gp=0.jpg",
          id: 1,
        },
        {
          url: "https://img0.baidu.com/it/u=2217285009,2181010306&fm=11&fmt=auto&gp=0.jpg",
          id: 2,
        },
      ],
      goodsRecommendGoodsList: [
        {
          url: "https://img0.baidu.com/it/u=2529224896,2406165372&fm=11&fmt=auto&gp=0.jpg",
          id: 0,
        },
        {
          url: "https://img0.baidu.com/it/u=2230965783,1569785427&fm=26&fmt=auto&gp=0.jpg",
          id: 1,
        },
        {
          url: "https://img0.baidu.com/it/u=2217285009,2181010306&fm=11&fmt=auto&gp=0.jpg",
          id: 2,
        },
        {
          url: "https://img1.baidu.com/it/u=3326470141,2162636233&fm=26&fmt=auto&gp=0.jpg",
          id: 3,
        },
        {
          url: "https://img1.baidu.com/it/u=335183132,539509064&fm=26&fmt=auto&gp=0.jpg",
          id: 4,
        },
        {
          url: "https://img0.baidu.com/it/u=4029517533,3562204351&fm=26&fmt=auto&gp=0.jpg",
          id: 5,
        },
      ],
      goodsInfo: [],
      cont: 1,
      getAddress: [],
      addressId: "",
    };
  }
  componentDidMount() {
    console.log(this.props);
    this.setState({
      productId: this.props.match.params.id,
    });
    this.productDetails();
    this.getAddress();
    this.getSellList();
    this.gettuiSellList();
  }

  bigImg(url) {
    this.setState({
      detailsImg: url,
    });
  }
  productDetails() {
    let goodsId = this.props.match.params.id;
    console.log(goodsId);
    request("post", "/api/goods/goodsInfo", {
      goodsId,
      userId: cookie.load("infoData") ? cookie.load("infoData").id : "",
    }).then((res) => {
      console.log(res);
      this.setState(
        {
          goodsInfo: res.data,
          goodsId: res.goodsInfo.productId,
          isYinPian: res.goodsInfo.isYinpian,
        },
        function () {
          if (!this.state.detailsImg) {
            this.setState({
              detailsImg: res.data.imgurl,
            });
          }
        }
      );
    });
  }
  add() {
    const { cont } = this.state;
    this.setState({
      cont: cont * 1 + 1,
    });
  }
  sub() {
    const { cont } = this.state;
    this.setState(
      {
        cont: cont * 1 - 1,
      },
      function () {
        if (cont < 1) {
          this.setState({
            cont: 1,
          });
        }
      }
    );
  }
  // 加入购物车
  joinCart() {
    let that = this;
    let cont = this.state.cont;
    let goodsId = this.props.match.params.id;
    if (cookie.load("infoData")) {
      request("post", "/api/member/cart/add", {
        userId: cookie.load("infoData").id,
        goodsId: goodsId,
        productId: that.state.goodsId,
        num: cont,
      }).then((res) => {
        message.success(res.msg);
      });
    } else {
      message.warning("请先进行登录~");
    }
  } //收藏
  collectOrCancel() {
    const { productId } = this.state;
    if (cookie.load("infoData")) {
      request("post", "/api/member/goods/collectOrCancel", {
        userId: cookie.load("infoData").id,
        goodsId: productId,
      }).then((res) => {
        this.productDetails();
        message.success(res.msg); // console.log(res);
      });
    } else {
      message.warning("请先进行登录~");
    }
  } //get收货
  getAddress() {
    let userId = cookie.load("infoData") ? cookie.load("infoData").id : "";
    request("post", "/api/member/address/list", {
      userId,
    }).then((res) => {
      console.log(res);
      if (res.code == 0) {
        res.data.map((item) => {
          if (item.defaultValue == 0) {
            this.setState({
              addressId: item.id,
            });
          }
        });
        this.setState({
          getAddress: res.data,
        });
      }
    });
  }
  goBuy = () => {
    if (cookie.load("infoData")) {
      let user = cookie.load("infoData");
      const { addressId, getAddress } = this.state; // console.log(this.state.goodsId);
      let id = this.state.productId;
      let cont = this.state.cont;
      let that = this;
      if (getAddress.length != 0) {
        // if (addressId ? addressId : getAddress[0].id) {
        request("post", "/api/order/goods/check", {
          userId: user.id,
          goodsId: id,
          productId: that.state.goodsId,
          total: cont,
          addressId: addressId ? addressId : getAddress[0].id,
        }).then((res) => {
          console.log(res.data);
          if (res.code === 0) {
            this.props.history.push({
              pathname: "/home/confirmOrder",
              state: { goodsInfo: res.data },
            });
          } else {
            message.warning(res.msg);
          }
        });
        // } else {
        //   message.warning("请先去个人中心添加收货地址");
        // }
      } else {
        message.warning("请先去个人中心添加收货地址");
      }
    } else {
      message.warning("请先进行登录~");
    }
    // console.log(cookie.load('infoData'));
  };
  setNum(e) {
    this.setState({
      cont: e.target.value - 0,
    });
  }
  // 买家在看
  getSellList() {
    request("post", "/api/goods/specialDrug", {
      status: "1",
      pageNum: "1",
      pageSize: "3",
    }).then((res) => {
      console.log(res);
      this.setState({
        sellList: res.data.list,
      });
    });
  }
  // 商家推荐
  gettuiSellList() {
    request("post", "/api/goods/specialDrug", {
      status: "1",
      pageNum: "2",
      pageSize: "6",
    }).then((res) => {
      console.log(res);
      this.setState({
        tuisellList: res.data.list,
      });
    });
  }

  render() {
    const {
      detailsImg,
      buyerLookList,
      goodsRecommendGoodsList,
      goodsInfo,
      isYinPian,
    } = this.state;
    const isshow = () => {
      console.log(isYinPian);
      if (isYinPian == 1) {
        return (
          <span style={{ marginLeft: "20px" }}>
            {(this.state.cont * (this.state.goodsInfo.coefficient-0)).toFixed(2)}{this.state.goodsInfo.company}
          </span>
        );
      }
    };
    console.log(this.state.cont);
    return (
      <div className="productDetailsW">
        <div className="productDetails">
          <div className="productDetailsTop">
            <div className="productInfo">
              <div className="productInfoLeft">
                <BigImg detailsImg={detailsImg} />
                <div className="productInfoImgList">
                  {goodsInfo.goodsMainImages &&
                    goodsInfo.goodsMainImages.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => this.bigImg(item.imgurl)}
                        className="productInfoImgItem"
                      >
                        <img src={item.imgurl} alt="" />
                      </div>
                    ))}
                </div>
                <div className="shoucang">
                  <img src={goodsInfo.mg === 0 ? shoucang : shoucang2} alt="" />
                   <span onClick={() => this.collectOrCancel()}>收藏</span>     
                </div>
              </div>
              <div className="productInfoRight">
                <div className="productInfoName">{goodsInfo.name}</div>
                <div className="productInfoContent">
                  <div className="productInfoContentItem">
                    <div className="item">
                      <span className="name">品名：</span>
                      <span className="value">{goodsInfo.title}</span>
                    </div>
                    <div className="item">
                      <span className="name">商品规格：</span>
                      <span className="value">{goodsInfo.unit}</span>
                    </div>
                  </div>
                  <div className="productInfoContentItem">
                    <div className="item">
                      <span className="name">产地：</span>
                      <span className="value">{goodsInfo.origin}</span>
                    </div>
                    <div className="item">
                      <span className="name">库存数量：</span>
                      <span className="value">{goodsInfo.stock}</span>
                    </div>
                  </div>
                  <div className="productInfoContentItem">
                    <div className="item">
                      <span className="name">厂家：</span>
                      <span className="value">{goodsInfo.factory}</span>
                    </div>
                  </div>
                </div>
                <div className="productInfoPrice">￥{goodsInfo.priceH}
                {goodsInfo.isYinpian == 1 ? "元/kg" : ""}
                </div>
                {/* <div className="productInfoTips">
                  <span className="name">活动：</span>
                  <span className="tips">
                    该商品在2021-04-06 00:00:00至2021-09-08
                    23:59:59期间，可享受特惠价
                    <span className="price">￥29.90</span>
                  </span>
                </div> */}
                <div className="productInfoNum" style={{ lineHeight: "35px" }}>
                  <span className="name">数量：</span>
                  <span className="inputNum">
                    <span className="sub" onClick={() => this.sub()}>
                      -
                    </span>
                    <input
                      className="input"
                      onChange={(e) => this.setNum(e)}
                      value={this.state.cont < 1 ? 1 : this.state.cont}
                      type="text"
                    />
                    <span className="add" onClick={() => this.add()}>
                      +
                    </span>
                    {/* {this.state.cont * c.toFixed(2)} */}

                    {isshow()}
                  </span>
                  {/* <span className="unit">包</span> */}
                </div>
                <div className="maiBtn">
                  <div className="addCart" onClick={this.joinCart.bind(this)}>
                    加入购物车
                  </div>
                  <div className="buy" onClick={this.goBuy}>
                    立即购买
                  </div>
                </div>
              </div>
            </div>
            <div className="buyerLook">
              <div className="buyerLookTitle">
                <div className="heng"></div>
                <div className="title">买家还在看</div>
                <div className="heng"></div>
              </div>
              <div className="buyerLookGoodsList">
                {this.state.sellList.map((item) => (
                  <Link target="_blank" to={"/home/productDetails/" + item.id}>
                    <div key={item.id} className="buyerLookGoodsItem">
                      <div
                        style={{
                          backgroundSize: "100% 100%",
                        }}
                        className="buyerLookGoodsItemImg"
                      >
                        <div id="img">
                          <img src={item.imgurl} alt="" />
                        </div>
                        <div className="price">￥{item.priceH}</div>
                      </div>
                      <div className="name">{item.title}</div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="productDetailsBottom">
            <div className="goodsRecommend">
              <div className="goodsRecommendTitle">
                <div className="heng"></div>
                <div className="title">商品推荐</div>
                <div className="heng"></div>
              </div>
              <div className="goodsRecommendGoodsList">
                {this.state.tuisellList.map((item) => (
                  <Link target="_blank" to={"/home/productDetails/" + item.id}>
                    <div key={item.id} className="buyerLookGoodsItem">
                      <div
                        style={{
                          backgroundSize: "100% 100%",
                        }}
                        className="buyerLookGoodsItemImg"
                      >
                        <div
                          id="img1"
                          style={{
                            width: 160,
                            height: 120,
                          }}
                        >
                          <img
                            style={{
                              backgroundColor: "red",
                              width: 160,
                              height: 100,
                            }}
                            src={item.imgurl}
                            alt=""
                          />
                        </div>
                        <div className="price" style={{ textAlign: "center" }}>
                          ￥{item.priceH}
                        </div>
                      </div>
                      <div className="name" style={{ textAlign: "center" }}>
                        {item.title}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="productDetailInfo">
              <div className="productDetailInfoTitle">
                <div className="productDetailInfoTitleName">商品详情</div>
              </div>
              <div className="productDetailInfoContent">
                {goodsInfo.goodsImagesList &&
                  goodsInfo.goodsImagesList.map((item) => (
                    <img key={item.id} src={item.imgurl} alt="" />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
