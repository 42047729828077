import React, { Component } from "react";
import "./index.scss";
import { Pagination } from "antd";
import request from "../../../assets/js/http";
import cookie from "react-cookies";

export default class Boutique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      letter: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      // 功效分类列表
      gongxiaoList: [],
      // 形态分类列表
      xingtaiList: [],
      // 获取主题列表
      zhutiList: [],
      // 获取商品列表
      shangpinList: [],
      shangpinData: "",
    };
  }

  componentDidMount() {
    this.getGongxiaoList();
    this.getXingtaiList();
    this.getzhutiList();
    // this.getProductList();
    console.log(this.props.match.params.id);
    if(this.props.match.params.id==':id'){
      this.getProductList();
    }
    // console.log(this.props.location.match.params.id);

    console.log(this.props.location.state == null);
    if (this.props.match.params == null) {
      console.log(123);
      this.getProductList();
    } else {
      console.log(321);
      this.getNameList(this.props.match.params.id);
    }
  }

  // 跳转详情页
  goDetails(id) {
    this.props.history.push("/home/productDetails/" + id);
  }
  //获取功效分类列表
  getGongxiaoList() {
    request("post", "/api/goods/class/gxList", {}).then((res) => {
      // console.log(res);
      this.setState({
        gongxiaoList: res.data,
      });
    });
  }
  // 获取形态分类列表
  getXingtaiList() {
    request("post", "/api/goods/class/xtList", {}).then((res) => {
      // console.log(res);
      this.setState({
        xingtaiList: res.data,
      });
    });
  }
  // 获取主题 商品分类
  getzhutiList() {
    request("post", "/api/goods/class/ztList", {}).then((res) => {
      // console.log(res);
      this.setState({
        zhutiList: res.data,
      });
    });
  }
  // 获取商品列表
  getProductList() {
    request("post", "/api/goods/goods/list", {
      userId: cookie.load("infoData") ? cookie.load("infoData").id : "",
      pageNum: "1",
      pageSize: "10",
    }).then((res) => {
      console.log(res);
      this.setState({
        shangpinList: res.data.list,
        shangpinData: res.data,
      });
    });
  }
  // 名字搜索列表
  getNameList(item) {
    request("post", "/api/goods/goods/list", {
      userId: cookie.load("infoData") ? cookie.load("infoData").id : "",
      pageNum: "1",
      pageSize: "10",
      name: item,
    }).then((res) => {
      this.setState({
        shangpinList: res.data.list,
        shangpinData: res.data,
      });
    });
  }
  //商品分页
  onChange = (pageNum, pageSize) => {
    request("post", "/api/goods/goods/list", {
      userId: cookie.load("infoData") ? cookie.load("infoData").id : "",
      pageNum,
      pageSize,
    }).then((res) => {
      console.log(res);
      this.setState({
        shangpinList: res.data.list,
        shangpinData: res.data,
      });
    });
  };

  //拼音搜索
  pinyinSearch(item) {
    request("post", "/api/goods/goods/list", {
      userId: cookie.load("infoData") ? cookie.load("infoData").id : "",
      pageNum: "1",
      pageSize: "10",
      pinyin: item,
    }).then((res) => {
      this.setState({
        shangpinList: res.data.list,
        shangpinData: res.data,
      });
    });
  }
  // 功效分类列表
  gongxiaoSearch(id) {
    request("post", "/api/goods/goods/list", {
      userId: cookie.load("infoData") ? cookie.load("infoData").id : "",
      pageNum: "1",
      pageSize: "10",
      gxId: id,
    }).then((res) => {
      this.setState({
        shangpinList: res.data.list,
        shangpinData: res.data,
      });
    });
  }
  // 形态分类列表
  xingtaiSearch(id) {
    request("post", "/api/goods/goods/list", {
      userId: cookie.load("infoData") ? cookie.load("infoData").id : "",
      pageNum: "1",
      pageSize: "10",
      xtId: id,
    }).then((res) => {
      this.setState({
        shangpinList: res.data.list,
        shangpinData: res.data,
      });
    });
  }
  // 主题类分类列表
  zhutiSearch(id) {
    request("post", "/api/goods/goods/list", {
      userId: cookie.load("infoData") ? cookie.load("infoData").id : "",
      pageNum: "1",
      pageSize: "10",
      ztId: id,
    }).then((res) => {
      this.setState({
        shangpinList: res.data.list,
        shangpinData: res.data,
      });
    });
  }
  render() {
    const {
      letter,
      gongxiaoList,
      xingtaiList,
      zhutiList,
      shangpinList,
      shangpinData,
    } = this.state;
    return (
      <div>
        <div className="root">
          <div className="title">
            <div className="all" onClick={()=>this.getProductList()}>全部商品</div>
            <div className="fenlei">
              <div className="fenlei_box">拼音检索</div>
              <div className="list1">
                {letter.map((item, index) => {
                  return (
                    <div
                      className="list2"
                      onClick={this.pinyinSearch.bind(this, item)}
                      key={index}
                    >
                      {item}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="fenlei">
              <div className="fenlei_box">功效分类</div>
              <div className="list1">
                {gongxiaoList.map((item) => {
                  return (
                    <div
                      className="list2"
                      onClick={this.gongxiaoSearch.bind(this, item.id)}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="fenlei">
              <div className="fenlei_box">形态分类</div>
              <div className="list1">
                {xingtaiList.map((item) => {
                  return (
                    <div
                      className="list2"
                      onClick={this.xingtaiSearch.bind(this, item.id)}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="fenlei">
              <div className="fenlei_box">主题商品</div>
              <div className="list1">
                {zhutiList.map((item) => {
                  return (
                    <div
                      className="list2"
                      onClick={this.zhutiSearch.bind(this, item.id)}
                      key={item.id}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="goodsList">
            {shangpinList.map((item) => {
              return (
                <div
                  className="goodItem"
                  onClick={this.goDetails.bind(this, item.id)}
                  key={item.id}
                >
                  <div className="img">
                    <img src={item.imgurl} alt="暂无图片" />
                  </div>
                  <div className="name">{item.name}</div>
                  <div className="price">
                    <span>￥</span>
                    {item.priceH}
                    {item.isYinpian == 1 ? "/kg" : ""}
                  </div>
                  <div className="adderss">
                    <div>{item.unit}</div>
                    <div>{item.origin}</div>
                  </div>
                  {/* <div className="adderss">
                    质量标准:《中国药典》2020年版一部
                  </div> */}
                  <div className="adderss">厂家：{item.factory}</div>
                </div>
              );
            })}
          </div>
        </div>
        <Pagination
          onChange={this.onChange}
          pageSize="10"
          total={shangpinData.total}
        />
      </div>
    );
  }
}
