import React, { Component } from "react";
import "./index.scss";
import { Pagination } from "antd";
import homeTimeLimitBanner from "../../../assets/image/home_timelimit.png";
import xianshiBg from "../../../assets/image/xianshiBg.png";
import request from "../../../assets/js/http";
import CountDown from "../../../components/dataTime/index.jsx";
export default class HomeTimeLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 列表
      goodList: [],
      total: "",
    };
  }
  componentDidMount() {
    this.getList();
  }
  getList() {
    request("post", "/api/goods/getGoodsSpike", {
      pageNum: "1",
      pageSize: "10",
    }).then((res) => {
      console.log("--------");
      console.log(res);
      this.setState({
        goodList: res.data,
        total: res.data.total,
      });
    });
  }
  goDetails(id) {
    this.props.history.push("/home/productDetails/" + id);
  }
  render() {
    const { goodList, total } = this.state;
    console.log(goodList.list);
    return (
      <div className="homeTimeLimit">
        <div className="homeTimeLimitBanner">
          <img src={homeTimeLimitBanner} alt="" />
        </div>
        <div className="homeTimeLimitGoodsList">
          {goodList.list &&
            goodList.list.map((item) => (
              <div
                className="homeTimeLimitGoodsItem"
                onClick={this.goDetails.bind(this, item.id)}
              >
                <div className="homeTimeLimitGoodsItemImg">
                  <img src={item.imgurl} alt="" />
                </div>
                <div className="homeTimeLimitGoodsName">{item.name}</div>
                <div className="progress">
                  <div className="homeTimeLimitGoodsProgressW">
                    <div
                      style={{ width: item.percentage }}
                      className="homeTimeLimitGoodsProgress"
                    ></div>
                  </div>
                  <div className="yiQiang">{item.percentage}</div>
                </div>
                <CountDown endTime={item.endAt} />
                <div className="homeTimeLimitGoodsItemPrice">
                  <div className="discountedPrice">
                    ￥<span className="discountedPriceNum">{item.price}</span>
                    <span
                      style={{ background: `url(${xianshiBg})` }}
                      className="xianshiBg"
                    > 限时价
                    </span>
                    <span className="discountPriceNum">￥{item.priceY}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
                
        <Pagination pageSize="10" defaultCurrent={1} total={total} />
              
      </div>
    );
  }
}
