import React, { Component } from "react";
import "./index.scss";
import cookie from "react-cookies";
import request from "../../assets/js/http";
import { message } from "antd";
export default class ShoppingCarts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: [],
      isChecked: false,
      index: -1,
      allChecked: false,
      // 选中数组
      seledList: [],
      isShow: false,
      getAddress: [],
      id: "",
      long: 0,
    };
  }
  componentDidMount() {
    this.getCartList();
    this.getAddress();
  }
  // 获取购物车列表
  getCartList() {
    let that = this;
    request("post", "/api/member/cart/list", {
      userId: cookie.load("infoData").id,
    }).then((res) => {
      console.log(res);
      that.setState({
        cartList: res.data.map((item) => {
          item.isChecked = false;
          return item;
        }),
      });
    });
  }
  //   点击加数量
  add = (index, cartId) => {
    console.log(index);
    let cartList = this.state.cartList;
    cartList[index].num += 1;
    this.setState(
      {
        cartList: cartList,
      },
      function () {
        request("post", "/api/member/cart/addAndSub", {
          userId: cookie.load("infoData").id,
          cartId,
          num: cartList[index].num,
        }).then((res) => {
          message.success("添加成功");
        });
      }
    );
  };
  //   点击减数量
  sub(index, cartId) {
    let cartList = this.state.cartList;
    cartList[index].num -= 1;
    if (cartList[index].num === 0) {
      message.warning("已达最小数量");
      cartList[index].num = 1;
    } else {
      this.setState(
        {
          cartList: cartList,
        },
        function () {
          request("post", "/api/member/cart/addAndSub", {
            userId: cookie.load("infoData").id,
            cartId,
            num: cartList[index].num,
          }).then(message.success("操作成功"));
        }
      );
    }
  }
  //   删除商品
  deleOne(cartId) {
    console.log(cartId);
    let cartList = this.state.cartList;
    request("post", "/api/member/cart/remove", {
      userId: cookie.load("infoData").id,
      cartIds: cartId,
    }).then((res) => {
      message.success(res.msg);
      this.getCartList();
      this.setState({
        cartList: cartList,
      });
    });
  }
  // 是否选中
  isChecked(index) {
    let carList = this.state.cartList;
    let a1 = 0;
    carList[index].isChecked = !carList[index].isChecked;
    this.setState({
      carList: carList,
    });
    let list = carList.map((item, index) => {
      if (item.isChecked === false) {
        return "";
      } else {
        a1++;
        return item.cartId;
      }
    });
    let newList = list.filter((x) => x !== "");
    console.log(newList);
    if (newList.length > 0) {
      this.setState({
        isShow: true,
        long: newList.length,
      });
    } else {
      this.setState({
        isShow: false,
        long: 0,
      });
    }
    this.setState({
      seledList: newList,
    });
    if (a1 === carList.length) {
      this.setState({
        allChecked: true,
      });
    } else {
      this.setState({
        allChecked: false,
      });
    }
  }
  // 全选
  allChecked() {
    let carList = this.state.cartList;
    console.log(carList);
    carList.map((item) => {
      item.isChecked = !this.state.allChecked;
      return item;
    });
    this.setState({
      carList: carList,
      allChecked: !this.state.allChecked,
    });
    console.log(!this.state.allChecked);
    if (!this.state.allChecked) {
      this.setState({
        seledList: this.state.cartList.map((item) => {
          return item.cartId;
        }),
        isShow: !this.state.allChecked,
        long: carList.length,
      });
    } else {
      this.setState({
        seledList: [],
        isShow: !this.state.allChecked,
        long: 0,
      });
    }
  }
  //get收货
  getAddress() {
    let userId = cookie.load("infoData").id;
    request("post", "/api/member/address/list", {
      userId,
    }).then((res) => {
      console.log(res);
      if (res.code == 0) {
        res.data.map((item) => {
          if (item.defaultValue == 0) {
            this.setState({
              id: item.id,
            });
          }
        });
        this.setState({
          getAddress: res.data,
        });
      }
    });
  }
  //去结算
  jumpConfirm() {
    // let a1 = 0;
    let carList = this.state.cartList;
    let userId = cookie.load("infoData").id;
    const { cartList, id, getAddress } = this.state;
    let list = carList.map((item, index) => {
      if (item.isChecked === false) {
        return "";
      } else {
        // a1++;
        return item.cartId;
      }
    });
    let newList = list.filter((x) => x !== "");
    console.log(newList);
    if (id ? id : getAddress[0].id) {
      if (newList.length != 0) {
        request("post", "/api/order/cart/cartCheck", {
          userId,
          cartList: newList,
          addressId: id ? id : getAddress[0].id,
        }).then((res) => {
          if (res.code == 0) {
            this.props.history.push({
              pathname: "/home/confirmOrder",
              state: { cartIdList: newList, info: res.data },
            });
          }
        });
      } else {
        message.warning("请选择商品");
      }
    } else {
      message.warning("请先去个人中心添加收货地址");
    }
  }
  render() {
    return (
      <div className="shoppingCartsW">
        <div className="shoppingCarts">
          <div className="shoppingCartsTitle">
            <span className="title">
              全部商品({this.state.cartList.length})
            </span>
          </div>
          <div className="shoppingCartsContent">
            <div className="shopCartTable">
              <div className="shopCartTable_thead">
                <div className="shopCartTable_thead_tr">
                  <div className="checkbox">
                    <input
                      className="checkbox"
                      checked={this.state.allChecked}
                      onChange={this.allChecked.bind(this)}
                      type="checkbox"
                      name=""
                      id=""
                    />
                    <span>全选</span>
                  </div>
                  <div>商品信息</div>
                  <div>规格</div>
                  <div>数量</div>
                  <div>小计</div>
                  <div>操作</div>
                </div>
              </div>
              <div className="shopCartTable_tbody">
                {this.state.cartList.map((item, index) => (
                  <div className="shopCartTable_tbody_tr" key={item.goodsId}>
                    <div className="tbody_checkbox">
                      <input
                        type="checkbox"
                        checked={item.isChecked}
                        onChange={this.isChecked.bind(this, index)}
                      />
                      <img src={item.imgurl} alt="暂无" />
                    </div>
                    <div className="td_name">{item.name}</div>
                    <div className="td_spec">{item.spec}</div>
                    <div className="td_num">
                      <span
                        className="sub"
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                        }}
                        onClick={() => this.sub(index, item.cartId)}
                      >
                        -
                      </span>
                      <input
                        readOnly
                        style={{ textAlign: "center" }}
                        value={item.num}
                      />
                      <span
                        className="add"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.add(index, item.cartId)}
                      >
                        +
                      </span>
                    </div>
                    <div className="td_price">
                      ￥{item.priceH * item.coefficient * item.num}
                    </div>
                    <div
                      className="td_del"
                      onClick={this.deleOne.bind(this, item.cartId)}
                    >
                      删除
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="shoppingCartsFooter">
            <div className="shoppingCartsFooter_left">
              {/* <input className="checkboxAll" type="checkbox" name="" id="" />
              <span>全选</span>
              <span>删除</span> */}
            </div>
            <div className="shoppingCartsFooter_right">
              <span className="span">
                已选商品<span className="num">{this.state.long}</span>件
              </span>
              {/* <span className="span">
                合计：<span className="num">0.00</span>
              </span>
              <span className="span">
                运费：<span className="num">0.00</span>
              </span> */}
              <div
                onClick={() => this.jumpConfirm()}
                className={this.state.isShow ? "seleJIesuan" : "jiesuan"}
              >
                结算
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
