import React, { Component } from 'react';
import './index.scss';
import zfb from '../../../assets/image/zfb.png'
import vx from '../../../assets/image/vx.png'
import { Button, Pagination, Modal, Radio } from 'antd';
export default class BalanceIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPayModal: false,
            radioValue: 1
        }
    }
    showPayModal = () => {
        this.setState({
            isPayModal: true
        })
    };

    handlePayOk = () => {
        this.setState({
            isPayModal: false
        })
    };

    handlePayCancel = () => {
        this.setState({
            isPayModal: false
        })
    };
    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            radioValue: e.target.value
        })
    };
    render() {
        return (
            <div className="balanceIndex">
                <div className="balanceIndexTop">
                    <div className="balanceIndexTop_left">
                        可用余额：<span className="price">
                            ￥2345.00
                        </span>
                    </div>
                    <div className="balanceIndexTop_right">
                        <Button onClick={() => this.showPayModal()}>充值</Button>
                    </div>
                </div>
                <div className="balanceIndexContent">
                    <div className="balanceIndexContentTitle">
                        收支记录
                    </div>
                    <div className="balanceTable">
                        <table className="table">
                            <thead className="balanceTable_thead">
                                <tr className="balanceTable_thead_tr">
                                    <th>类型</th>
                                    <th>时间</th>
                                    <th>分值</th>
                                </tr>
                            </thead>
                            <tbody className="balanceTable_tbody">
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                                        <tr className="balanceTable_tbody_tr">
                                            <td>奖励</td>
                                            <td>2019-01-11 12:23</td>
                                            <td>+567</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <Pagination defaultCurrent={1} total={50} />,
                </div>
                <Modal title="充值" footer={[<Button>确认支付</Button>]} className="isPayModal" visible={this.state.isPayModal} onOk={this.handlePayOk} onCancel={this.handlePayCancel}>
                    <div className="payPrice">
                        <span className="payPriceSpan">充值金额</span>
                        <input className="payPriceInput" placeholder="请输入充值金额" type="text" />
                    </div>
                    <div className="paymentMethod">
                        <div className="paymentMethodDiv">
                            <span className="paymentMethodSpan">
                                付款方式
                            </span>
                            <Radio.Group onChange={this.onChange} value={this.state.radioValue}>
                                <Radio value={1}><img src={vx} alt="" /></Radio>
                                <Radio value={2}> <img src={zfb} alt="" /></Radio>
                            </Radio.Group>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}