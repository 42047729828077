import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import login from "../../assets/image/logo.png";
import myshopcart from "../../assets/image/myshopcart.png";
import request from "../../assets/js/http";
import cookie from "react-cookies";

import "./index.scss";
import { Input, Space, Button, Badge, message } from "antd";
const { Search } = Input;
class HeaderContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: [
        { name: "首页", id: "1", path: "/home/index", sta: true },
        { name: "问价", id: "2", path: "/home/boutique/:id", sta: false },
        { name: "特价药品", id: "3", path: "/home/homeSpecial", sta: false },
        { name: "限时抢购", id: "4", path: "/home/homeTimeLimit", sta: false },
        { name: "满减优惠", id: "5", path: "/home/homeDiscounts", sta: false },
        { name: "新品推荐", id: "6", path: "/home/homeNewProduct", sta: false },
      ],
      value: "",
      count: 0,
    };
  }
  componentDidMount() {
    if (cookie.load("infoData")) {
      let userId = cookie.load("infoData").id;
      request("post", "/api/member/cart/list", {
        userId,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.setState({
            count: res.data.length,
          });
        }
      });
    }
  }
  goCarts = () => {
    if (cookie.load("infoData")) {
      this.props.history.push("/home/shoppingCarts");
    } else {
      message.warning("请先登录");
    }
    // console.log(123);
  };

  change(e) {
    console.log(e);
    console.log(e.target.value);
    this.setState({
      value: e.target.value,
    });
  }
  render() {
    const onSearch = (value) => {
      console.log(value);
      if (!value) {
        message.warning("请输入搜索内容");
      } else {
        window.open("/home/boutique/" + value, "_blank");
        // this.props.history.push({pathname:'/home/boutique',state:{value:value}});
      }
    };
    const { tab, value } = this.state;
    return (
      <div className="headerContentW">
        <div className="headerContent">
          <div className="headerContentLogo">
            <img src={login} alt="" />
          </div>
          <div className="headerContentRight">
            <div className="headerContentRight_top">
              <div className="searchInput">
                <Space direction="vertical">
                  <Search
                    placeholder="请输入关键字"
                    allowClear
                    onSearch={onSearch}
                    onChange={this.change.bind(this)}
                    enterButton
                    value={value}
                    style={{ width: 720 }}
                  />
                </Space>
                ,
              </div>
              <div className="myShoppingCart">
                <Button onClick={this.goCarts}>
                  <img src={myshopcart} alt="" />
                  <span className="shopCartSpan">我的购物车</span>
                  <Badge count={this.state.count}></Badge>
                </Button>
              </div>
            </div>
            <div className="headerContentRight_bottom">
              <div className="linkList">
                {tab.map((item) => (
                  <NavLink
                    to={item.path}
                    key={item.id}
                    activeClassName="activeLinkItem"
                    className="linkItem"
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(HeaderContent);
