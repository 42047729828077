import axios from "axios";
axios.defaults.timeout = 100000;
let baseUrl = '/api'

// //console.log(Date().getTime());

axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": "application/json; charset=UTF-8",
    };
    return config
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // //console.log(response);
    if (response.data.errCode === 2) {
      ////console.log("过期");
    }
    return response;
  },
  (error) => {
    ////console.log("请求出错：", error);
  }
);
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
    }).then((response) => {
      if (response) {
        landing(url, params, response.data);
        resolve(response.data);
      }
    })
      .catch((error) => {
        reject(error);
      });
  });
}

export function post(url, data) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        //关闭进度条
        if (response) {
          resolve(response.data);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      (response) => {
        if (response) {
          resolve(response.data);
        }
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        msag(err);
        reject(err);
      }
    );
  });
}
//统一接口处理，返回数据
export default function request(fecth, url, param) {
  let _data = "";
  url = baseUrl + url
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case "get":
        ////console.log("begin a get request,and url:", url);
        get(url, param)
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            ////console.log("get request GET failed.", error);
            reject(error);
          });
        break;
      case "post":
        // //console.log(param);
        post(url, param)
          .then(function (response) {
            resolve(response);

          })
          .catch(function (error) {
            ////console.log("get request POST failed.", error);
            reject(error)
          });
        break;
      default:
        break;
    }
  });
}
//失败提示
function msag(err) {
  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        alert(err.response.data.error.details);
        break;
      case 401:
        alert("未授权，请登录");
        break;
      case 403:
        alert("拒绝访问");
        break;
      case 404:
        alert("请求地址出错");
        break;
      case 408:
        alert("请求超时");
        break;
      case 500:
        alert("服务器内部错误");
        break;
      case 501:
        alert("服务未实现");
        break;

      case 502:
        alert("网关错误");
        break;
      case 503:
        alert("服务不可用");
        break;
      case 504:
        alert("网关超时");
        break;
      case 505:
        alert("HTTP版本不受支持");
        break;
      default:
    }
  }
}
function landing(url, params, data) {
  if (data.code === -1) {
  }
}