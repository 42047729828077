import React, { Component } from 'react';
import './index.scss';
import { Pagination } from 'antd';
export default class Integral extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div className="integral">
                <div className="integralTop">
                    <div className="integralTop_left">
                        可用积分：<span className="price">
                            ￥2345.00
                        </span>
                    </div>
                    {/* <div className="integralTop_right">
                        <Button onClick={() => this.showPayModal()}>充值</Button>
                    </div> */}
                </div>
                <div className="integralContent">
                    <div className="integralContentTitle">
                        积分明细
                    </div>
                    <div className="balanceTable">
                        <table className="table">
                            <thead className="balanceTable_thead">
                                <tr className="balanceTable_thead_tr">
                                    <th>类型</th>
                                    <th>时间</th>
                                    <th>分值</th>
                                </tr>
                            </thead>
                            <tbody className="balanceTable_tbody">
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8].map(item => (
                                        <tr className="balanceTable_tbody_tr">
                                            <td>奖励</td>
                                            <td>2019-01-11 12:23</td>
                                            <td>+567</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <Pagination defaultCurrent={1} total={50} />,
                </div>
            </div>
        )
    }
}