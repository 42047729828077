import React, { Component } from "react";
import "./index.scss";
import request from "../../../assets/js/http.js";
import { Link } from "react-router-dom";
import specialgoodsType from "../../../assets/image/specialgoodsType.png";
import timelimitgoodsType from "../../../assets/image/timelimitgoodsType.png";
import discountsgoodsType from "../../../assets/image/discountsgoodsType.png";
import newproductgoodsType from "../../../assets/image/newproductgoodsType.png";
export default class GoodsType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teJiaList: [],
      xianShiList: [],
      manJianList: [],
      xinPinList: [],
    };
  }
  componentDidMount() {
    this.teJia();
    this.xianShi();
    this.manJian();
    this.xinPin();
  }
  //特价药品
  teJia() {
    request("post", "/api/goods/specialDrug", {
      status: 0,
      pageNum: 1,
      pageSize: 8,
    }).then((res) => {
      this.setState({
        teJiaList: res.data.list,
      });
    });
  }
  //限时抢购
  xianShi() {
    request("post", "/api/goods/getGoodsSpike", {
      pageNum: 1,
      pageSize: 8,
    }).then((res) => {
      this.setState({
        xianShiList: res.data.list,
      });
    });
  }
  //满减优惠
  manJian() {
    request("post", "/api/goods/specialDrug", {
      status: 3,
      pageNum: 1,
      pageSize: 8,
    }).then((res) => {
      this.setState({
        manJianList: res.data.list,
      });
    });
  }
  //新品推荐
  xinPin() {
    request("post", "/api/goods/specialDrug", {
      status: 1,
      pageNum: 1,
      pageSize: 8,
    }).then((res) => {
      this.setState({
        xinPinList: res.data.list,
      });
    });
  }
  render() {
    const { teJiaList, xianShiList, manJianList, xinPinList } = this.state;
    return (
      <div className="goodsTypeW">
        <div className="goodsType">
          <div className="goodsTypeList">
            <div className="goodsTypeItem">
              <div
                className="goodsTypeLeft"
                style={{
                  background: `#ffffff url(${specialgoodsType}) no-repeat 100% 100%`,
                  backgroundSize: "100% 100%",
                }}
              >
                <div className="title">特价药品</div>
                <div className="describe">周周特惠，天天低价</div>
                <Link to="/home/homeSpecial">
                  <div className="moreBtn" style={{ background: "#E0441A" }}>
                    查看更多&gt;
                  </div>
                </Link>
              </div>
              <div className="goodsTypeRight">
                <div className="goodsList">
                  {teJiaList &&
                    teJiaList.map((item) => (
                      <Link
                        target="_blank"
                        key={item.goodsId}
                        to={"/home/productDetails/" + item.goodsId}
                      >
                        <div className="goodsItem">
                          <div className="goodsImg">
                            <img src={item.imgurl} alt="" />
                          </div>
                          <div className="goodsName">{item.name}</div>
                          <div className="goodsPrice">
                            ￥
                            <span className="goodsPriceNum">
                              {item.priceH}
                              {item.isYinpian == 1 ? "/kg" : ""}
                            </span>
                          </div>
                          <div style={{ color: "#9A9A9A", fontSize: "12px" }}>
                            厂家：{item.factory}
                          </div>
                          <div className="goodsItemBottom">
                            <div className="goodsSpec">{item.unit}</div>
                            <div className="goodsCity">{item.origin}</div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div className="goodsTypeItem">
              <div
                className="goodsTypeLeft"
                style={{
                  background: `#ffffff url(${timelimitgoodsType}) no-repeat 100% 100%`,
                  backgroundSize: "100% 100%",
                }}
              >
                <div className="title">限时抢购</div>
                <div className="describe">周周特惠，天天低价</div>
                <Link to="/home/homeTimeLimit">
                  <div className="moreBtn" style={{ background: "#599AEE" }}>
                    查看更多&gt;
                  </div>
                </Link>
              </div>
              <div className="goodsTypeRight">
                <div className="goodsList">
                  {xianShiList &&
                    xianShiList.map((item) => (
                      <Link
                        target="_blank"
                        key={item.goodsId}
                        to={"/home/productDetails/" + item.goodsId}
                      >
                        <div className="goodsItem">
                          <div className="goodsImg">
                            <img src={item.imgurl} alt="" />
                          </div>
                          <div className="goodsName">{item.name}</div>
                          <div className="goodsPrice">
                            ￥
                            <span className="goodsPriceNum">{item.price}</span>
                          </div>
                          <div style={{ color: "#9A9A9A", fontSize: "12px" }}>
                            厂家：{item.factory}
                          </div>
                          <div className="goodsItemBottom">
                            <div className="goodsSpec">{item.unit}</div>
                            <div className="goodsCity">{item.city}</div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div className="goodsTypeItem">
              <div
                className="goodsTypeLeft"
                style={{
                  background: `#ffffff url(${discountsgoodsType}) no-repeat 100% 100%`,
                  backgroundSize: "100% 100%",
                }}
              >
                <div className="title">满减优惠</div>
                <div className="describe">周周特惠，天天低价</div>
                <Link to="/home/homeDiscounts">
                  <div className="moreBtn" style={{ background: "#6A8AD9" }}>
                    查看更多&gt;
                  </div>
                </Link>
              </div>
              <div className="goodsTypeRight">
                <div className="goodsList">
                  {manJianList &&
                    manJianList.map((item) => (
                      <Link
                        target="_blank"
                        key={item.goodsId}
                        to={"/home/productDetails/" + item.goodsId}
                      >
                        <div className="goodsItem">
                          <div className="goodsImg">
                            <img src={item.imgurl} alt="" />
                          </div>
                          <div className="goodsName">{item.name}</div>
                          <div className="goodsPrice">
                            ￥
                            <span className="goodsPriceNum">
                              {item.priceH}
                              {item.isYinpian == 1 ? "元/kg" : ""}
                            </span>
                          </div>
                          <div style={{ color: "#9A9A9A", fontSize: "12px" }}>
                            厂家：{item.factory}
                          </div>
                          <div className="goodsItemBottom">
                            <div className="goodsSpec">{item.unit}</div>
                            <div className="goodsCity">{item.origin}</div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
            <div className="goodsTypeItem">
              <div
                className="goodsTypeLeft"
                style={{
                  background: `#ffffff url(${newproductgoodsType}) no-repeat 100% 100%`,
                  backgroundSize: "100% 100%",
                }}
              >
                <div className="title">新品推荐</div>
                <div className="describe">周周特惠，天天低价</div>
                <Link to="/home/homeNewProduct">
                  <div className="moreBtn" style={{ background: "#F5B143" }}>
                    查看更多&gt;
                  </div>
                </Link>
              </div>
              <div className="goodsTypeRight">
                <div className="goodsList">
                  {xinPinList &&
                    xinPinList.map((item) => (
                      <Link
                        target="_blank"
                        key={item.goodsId}
                        to={"/home/productDetails/" + item.goodsId}
                      >
                        <div className="goodsItem">
                          <div className="goodsImg">
                            <img src={item.imgurl} alt="" />
                          </div>
                          <div className="goodsName">{item.name}</div>
                          <div className="goodsPrice">
                            ￥
                            <span className="goodsPriceNum">
                              {item.priceH}
                              {item.isYinpian == 1 ? "元/kg" : ""}
                            </span>
                          </div>
                          <div className="goodsItemBottom1">
                            <div
                              className="goodsSpec"
                              title={item.unit}
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              规格：{item.unit}
                            </div>
                            <div
                              className="goodsCity"
                              title={item.origin}
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              厂家：{item.origin}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
